import React from "react";

import {
  renderMatchQuarterNumInNumeric,
  renderQuarterInMillisecondsTime,
} from "../../../meta/renderMatchBlocks";

import "./ControlButtons.css";

export default function ControlButtons(props) {
  const StartButton = (
    <div className="btn-cont btn-one btn-start" onClick={props.handleStart}>
      Start
    </div>
  );
  const ActiveButtons = (
    <div className="btn-grp">
      {renderQuarterInMillisecondsTime(props.quarterTime) <= props.time && (
        <div className="btn-cont btn-two" onClick={props.handleReset}>
          {renderMatchQuarterNumInNumeric(props.numQuarter) >
          props.currentQuarter
            ? "Stop Quarter"
            : "Stop Match"}
        </div>
      )}
      <div className="btn-cont btn-one" onClick={props.handlePauseResume}>
        {props.isPaused ? "Resume" : "Pause"}
      </div>
    </div>
  );

  return (
    <div className="Control-Buttons">
      <div>{props.active ? ActiveButtons : StartButton}</div>
    </div>
  );
}
