import React, { Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import HomePage from "./RootPage/HomePage/HomePage";
import Page404 from "./RootPage/page404/Page404";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./RootPage/auth/login/Login";
import AllUsers from "./RootPage/auth/allUsers/AllUsers";

import AllTeams from "./RootPage/team/allTeams/AllTeams";
import ViewTeam from "./RootPage/team/viewTeam/ViewTeam";
import AllMatches from "./RootPage/match/pages/allMatches/AllMatches";
import MatchViewPage from "./RootPage/match/pages/matchViewPage/MatchViewPage";
import LiveScoringPage from "./RootPage/scoring/pages/liveScoringPage/LiveScoringPage";
import FinishedMatchScore from "./RootPage/scoring/pages/finishedMatchScore/FinishedMatchScore";
import AllTournament from "./RootPage/tournament/pages/allTournament/AllTournament";
import ViewTournamentPage from "./RootPage/tournament/pages/viewTournamentPage/ViewTournamentPage";

function App() {
  const { pathname } = useLocation();

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <ProtectedRoute path="/" exact component={HomePage} />
          <ProtectedRoute path="/user" exact component={AllUsers} />
          <ProtectedRoute path="/tournament" exact component={AllTournament} />
          <ProtectedRoute
            path="/tournament/:tournamentId"
            exact
            component={ViewTournamentPage}
          />
          <ProtectedRoute path="/team" exact component={AllTeams} />
          <ProtectedRoute path="/match" exact component={AllMatches} />
          <ProtectedRoute
            path="/match/:matchId"
            exact
            component={MatchViewPage}
          />
          <ProtectedRoute path="/team/:teamPath" exact component={ViewTeam} />
          <ProtectedRoute
            path="/live-scoring/:scoreCardId"
            exact
            component={LiveScoringPage}
          />
          <ProtectedRoute
            path="/score/:scoreCardId"
            exact
            component={FinishedMatchScore}
          />
          <Route path="/login" exact render={(props) => <Login />} />
          <Route path="*" exact={true} component={Page404} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
