import { AiFillTrophy } from "react-icons/ai";
import moment from "moment";

import "./MatchResultCard.css";
import { useEffect, useState } from "react";

function MatchResultCard({ match }) {
  console.log(match);
  let [teamOneNumP, setTeamOneNumP] = useState(0);
  let [teamTwoNumP, setTeamTwoNumP] = useState(0);

  useEffect(() => {
    let tempTeamOneNumP = 0;
    match?.scoreCard?.teamOnePenalties &&
      (match?.scoreCard?.teamOnePenalties).split("").forEach((character) => {
        if (character === "S") {
          tempTeamOneNumP = tempTeamOneNumP + 1;
          setTeamOneNumP(tempTeamOneNumP);
        }
      });

    let tempTeamTwoNumP = 0;
    match?.scoreCard?.teamTwoPenalties &&
      (match?.scoreCard?.teamTwoPenalties).split("").forEach((character) => {
        if (character === "S") {
          tempTeamTwoNumP = tempTeamTwoNumP + 1;
          setTeamTwoNumP(tempTeamTwoNumP);
        }
      });
  }, []);

  return (
    <div className="match-card">
      <h5>{moment(match?.startTime).format("MMMM Do YYYY, h:mm:ss a")}</h5>
      <p>
        <a href={`/match/${match.matchId}`} className="a-tab-border-bottom">
          {match?.name}
        </a>
        , {match?.location}, {moment(match?.startTime).format("MMM Do YYYY")},{" "}
        <a
          href={`/tournament/${match.tournament.tournamentId}/`}
          className="a-tab-border-bottom"
        >
          {match?.tournament?.name}
        </a>
      </p>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamOne?.path}`}>
          <h4>{match?.teamOne?.name}</h4>
        </a>
        {match?.teamOne?.teamId === match?.winner?.teamId && (
          <AiFillTrophy size={20} />
        )}
        <span className="ui green label">
          G - {match?.scoreCard?.teamOneGoals}
        </span>
        {match?.scoreCard?.penaltyStarted === "YES" && (
          <span className="ui orange label">P - {teamOneNumP}</span>
        )}
      </div>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamTwo?.path}`}>
          <h4>{match?.teamTwo?.name}</h4>
        </a>
        {match?.teamTwo?.teamId === match?.winner?.teamId && (
          <AiFillTrophy size={20} />
        )}
        <span className="ui green label">
          G - {match?.scoreCard?.teamTwoGoals}
        </span>
        {match?.scoreCard?.penaltyStarted === "YES" && (
          <span className="ui orange label">P - {teamTwoNumP}</span>
        )}
      </div>
      <p className="match-yet">
        {match?.winner === "DRAW" ? "Match is tied" : match?.winner?.name} won
        this match
      </p>
      <div className="match-btn-section">
        <a className="match-summary" href={`/match/${match.matchId}`}>
          Summary
        </a>
        <a
          className="match-summary"
          href={`/score/${match.scoreCard.scoreCardId}/`}
        >
          Scorecard
        </a>
        <a
          className="match-summary"
          href={`/tournament/${match.tournament.tournamentId}/`}
        >
          Series Home
        </a>
      </div>
    </div>
  );
}

export default MatchResultCard;
