import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import TextIcon from "../TextIcon";

import "./SideMenu.css";

const adminRoutesWithRootPage = [
  {
    title: "Home",
    route: "/",
    icon: "home",
  },
  {
    title: "Tournament",
    route: "/tournament",
    icon: "trophy",
  },
  {
    title: "Match",
    route: "/match",
    icon: "circle",
  },
  {
    title: "Team",
    route: "/team",
    icon: "chess board",
  },
  {
    title: "User",
    route: "/user",
    icon: "user outline",
  },
];

const adminRoutesWithSubPage = [
  {
    title: "Home",
    route: "/",
    icon: "home",
  },
  {
    title: "Tournament",
    route: "/tournament",
    icon: "trophy",
  },
  {
    title: "Match",
    route: "/match",
    icon: "circle",
  },
  {
    title: "Team",
    route: "/team",
    icon: "chess board",
  },
  {
    title: "User",
    route: "/user",
    icon: "user outline",
  },
];

const SideMenu = ({ smallMenu }) => {
  const [activeItem, setActiveItem] = useState(
    adminRoutesWithRootPage[0].title
  );

  const handleItemClick = (value) => {
    setActiveItem(value);
  };

  const handleLogout = () => {
    localStorage.removeItem("h-kck-auth-firstName");
    localStorage.removeItem("h-kck-auth-lastName");
    localStorage.removeItem("h-kck-auth-email");
    localStorage.removeItem("h-kck-auth-role");
    localStorage.removeItem("h-kck-auth-token");
    localStorage.removeItem("h-kck-is-valid-auth");

    window.location.href = "/login";
  };

  const getUserRoutes = () => {
    if (
      localStorage.getItem("tg-auth-role") === "ADMIN" &&
      localStorage.getItem("tg-site-domain") === process.env.REACT_APP_API_HOST
    ) {
      return adminRoutesWithRootPage;
    }
    return adminRoutesWithSubPage;
  };

  return (
    <Menu
      fixed="left"
      borderless
      className={(smallMenu ? "small-side" : "") + " side"}
      vertical
    >
      {getUserRoutes().map(({ title, icon, route }) => (
        <Menu.Item
          as={Link}
          to={route}
          name={icon}
          active={activeItem === title}
          onClick={() => handleItemClick(title)}
          key={title}
        >
          <TextIcon
            hideText={smallMenu}
            color={activeItem === title ? "blue" : "black"}
            name={icon}
          >
            {title}
          </TextIcon>
        </Menu.Item>
      ))}
      <Menu.Item onClick={handleLogout} className="logout-btn" name={"user"}>
        <TextIcon hideText={smallMenu} color="black" name="angle double left">
          Log Out
        </TextIcon>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
