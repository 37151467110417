import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, ModalFooter, Modal, ModalBody } from "reactstrap";

import ScoreBoard from "../../../../components/ScoreBoard/ScoreBoard";
import StopWatch from "../../../../components/StopWatch/StopWatch";
import AddGoal from "../../components/addGoal/AddGoal";
import {
  readScoreCardById,
  updateScoreCardPenaltyState,
  updateScoreCardQuarterTime,
} from "../../../../api/scoreCardAPI";
import GoalListInMatch from "../../components/goalListInMatch/GoalListInMatch";
import { updateMatchStatus, updateMatchWinner } from "../../../../api/matchAPI";
import PenaltyBoard from "../../../../components/PenaltyBoard/PenaltyBoard";
import AddPenalty from "../../components/addPenalty/AddPenalty";
import PenaltyListInMatch from "../../components/penaltyListIInMatch/PenaltyListInMatch";

import "./LiveScoringPage.css";
import { findMatchWinner } from "../../../../meta/other";

function LiveScoringPage() {
  let { scoreCardId } = useParams();
  const [scoreCardItem, setScoreCardItem] = useState({});
  const [scoreBoardItem, setScoreBoardItem] = useState({});
  const [pending, setPending] = useState(true);
  const [time, setTime] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [matchFinishWarningModal, setMatchFinishWarningModal] = useState(false);
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [isPenaltyStarted, setIsPenaltyStarted] = useState(false);
  const [matchFinishClickModal, setMatchFinishClickModal] = useState(false);
  const [matchWinState, setMatchWinState] = useState("");

  const pauseBtnClick = (value) => {
    setTime(value);

    updateScoreCardQuarterTime({
      scoreCardId: scoreCardId,
      quarterTime: time,
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
          } else if (data["result"] === "FAILED") {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  };

  const goalAddSuccess = (value) => {
    if (value === 1) {
      setScoreBoardItem({
        ...scoreBoardItem,
        teamOne: {
          ...scoreBoardItem.teamOne,
          numOfGoals: parseInt(scoreBoardItem.teamOne.numOfGoals) + 1,
        },
      });
    } else if (value === 2) {
      setScoreBoardItem({
        ...scoreBoardItem,
        teamTwo: {
          ...scoreBoardItem.teamTwo,
          numOfGoals: parseInt(scoreBoardItem.teamTwo.numOfGoals) + 1,
        },
      });
    }
  };

  const quarterUpdateSuccess = () => {
    setScoreBoardItem({
      ...scoreBoardItem,
      period: parseInt(scoreBoardItem.period) + 1,
    });
  };

  const addGoalClick = () => {
    setIsPaused(true);
    pauseBtnClick(time);
  };

  const penaltyStartClick = () => {
    setIsPenaltyStarted(true);
    setMatchFinishWarningModal(false);

    updateScoreCardPenaltyState({
      scoreCardId: scoreCardId,
      state: "YES",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            updateScoreCardQuarterTime({
              scoreCardId: scoreCardId,
              quarterTime: 0,
            }).then((data) => {
              try {
                if (data["result"]) {
                  if (data["result"] === "SUCCESS") {
                  } else if (data["result"] === "FAILED") {
                  } else {
                  }
                } else {
                }
              } catch (error) {}
            });
          } else if (data["result"] === "FAILED") {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  };

  const matchFinishEndPoint = () => {
    updateScoreCardQuarterTime({
      scoreCardId: scoreCardId,
      quarterTime: 0,
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            updateMatchStatus({
              matchId: scoreCardItem?.match?.matchId,
              status: "DONE",
              scoreCardId,
            }).then((data) => {
              try {
                if (data["result"]) {
                  if (data["result"] === "SUCCESS") {
                    updateMatchWinner({
                      matchId: scoreCardItem?.match?.matchId,
                      winner:
                        matchWinState[0] === "DRAW"
                          ? "DRAW"
                          : matchWinState[1].teamId,
                    }).then((data) => {
                      try {
                        if (data["result"]) {
                          if (data["result"] === "SUCCESS") {
                            window.location.href = `/score/${scoreCardId}`;
                          } else if (data["result"] === "FAILED") {
                          } else {
                          }
                        } else {
                        }
                      } catch (error) {}
                    });
                  } else if (data["result"] === "FAILED") {
                  } else {
                  }
                } else {
                }
              } catch (error) {}
            });
          } else if (data["result"] === "FAILED") {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  };

  const matchFinishClick = () => {
    setMatchWinState(findMatchWinner(scoreBoardItem, scoreCardItem));
    setMatchFinishClickModal(true);
  };

  const penaltyAddSuccess = (value, status) => {
    if (value === 1) {
      if (status === "SUCCESS") {
        setScoreBoardItem({
          ...scoreBoardItem,
          teamOne: {
            ...scoreBoardItem.teamOne,
            penalties: scoreBoardItem.teamOne.penalties + "S",
          },
        });
      } else {
        setScoreBoardItem({
          ...scoreBoardItem,
          teamOne: {
            ...scoreBoardItem.teamOne,
            penalties: scoreBoardItem.teamOne.penalties + "F",
          },
        });
      }
    } else if (value === 2) {
      if (status === "SUCCESS") {
        setScoreBoardItem({
          ...scoreBoardItem,
          teamTwo: {
            ...scoreBoardItem.teamTwo,
            penalties: scoreBoardItem.teamTwo.penalties + "S",
          },
        });
      } else {
        setScoreBoardItem({
          ...scoreBoardItem,
          teamTwo: {
            ...scoreBoardItem.teamTwo,
            penalties: scoreBoardItem.teamTwo.penalties + "F",
          },
        });
      }
    }
  };

  useEffect(() => {
    readScoreCardById({ scoreCardId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            if (data["data"]?.match?.status === "DONE") {
              window.location.href = `/score/${scoreCardId}`;
            } else {
              setScoreCardItem(data["data"]);

              setScoreBoardItem({
                teamOne: {
                  name: data["data"].match.teamOne.name,
                  numOfGoals: data["data"].teamOneGoals,
                  numOfShots: data["data"].teamOneShots,
                  penalties: data["data"].teamOnePenalties,
                },
                teamTwo: {
                  name: data["data"].match.teamTwo.name,
                  numOfGoals: data["data"].teamTwoGoals,
                  numOfShots: data["data"].teamTwoShots,
                  penalties: data["data"].teamTwoPenalties,
                },
                period: data["data"].currentQuarter,
                tournamentName: data["data"].match.tournament.name,
              });

              setPending(false);

              if (data["data"].penaltyStarted === "NO") {
                setIsPenaltyStarted(false);
              } else if (data["data"].penaltyStarted === "YES") {
                setIsPenaltyStarted(true);
              }
            }
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [scoreCardId]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-5">
          <ScoreBoard scoreBoardItem={scoreBoardItem} />
          {!isPenaltyStarted && (
            <StopWatch
              scoreCardId={scoreCardId}
              currentQuarter={scoreBoardItem.period}
              currentQuarterTime={parseInt(scoreCardItem.currentQuarterTime)}
              pauseBtnClick={(value) => {
                pauseBtnClick(value);
              }}
              isPaused={isPaused}
              setIsPaused={setIsPaused}
              quarterTime={scoreCardItem?.match?.quarterTime}
              numQuarter={scoreCardItem?.match?.numQuarter}
              quarterUpdateSuccess={() => {
                quarterUpdateSuccess();
              }}
              time={time}
              setTime={setTime}
              setMatchFinishWarningModal={setMatchFinishWarningModal}
              matchFinishClick={matchFinishClick}
              havePenalty={
                scoreCardItem?.match?.numPenalty === "NO" ? false : true
              }
            />
          )}
          {isPenaltyStarted && (
            <PenaltyBoard
              scoreBoardItem={scoreBoardItem}
              matchFinishClick={matchFinishClick}
            />
          )}
        </div>

        <div className="col-sm-7">
          {!isPenaltyStarted && (
            <AddGoal
              scoreCardId={scoreCardId}
              matchId={scoreCardItem?.match?.matchId}
              teams={[
                scoreCardItem?.match?.teamOne,
                scoreCardItem?.match?.teamTwo,
              ]}
              quarter={scoreCardItem?.currentQuarter}
              quarterTime={time}
              goalAddSuccess={(value) => {
                goalAddSuccess(value);
              }}
              addGoalClick={addGoalClick}
              time={time}
            />
          )}
          {isPenaltyStarted && (
            <AddPenalty
              scoreCardId={scoreCardId}
              matchId={scoreCardItem?.match?.matchId}
              teams={[
                scoreCardItem?.match?.teamOne,
                scoreCardItem?.match?.teamTwo,
              ]}
              penaltyAddSuccess={(value, status) => {
                penaltyAddSuccess(value, status);
              }}
            />
          )}
          {isPenaltyStarted && (
            <PenaltyListInMatch
              matchId={scoreCardItem?.match?.matchId}
              teamOne={scoreCardItem?.match?.teamOne}
              teamTwo={scoreCardItem?.match?.teamTwo}
              scoreBoardItem={scoreBoardItem}
              matchFinishClick={matchFinishClick}
            />
          )}
          <GoalListInMatch
            matchId={scoreCardItem?.match?.matchId}
            teamOne={scoreCardItem?.match?.teamOne}
            teamTwo={scoreCardItem?.match?.teamTwo}
            scoreBoardItem={scoreBoardItem}
          />
        </div>
      </div>
      <Modal
        isOpen={matchFinishWarningModal}
        toggle={() => setMatchFinishWarningModal(!matchFinishWarningModal)}
        backdrop="static"
      >
        <ModalBody>
          Do you need to start Penalty round?
          {isFailSubmission && (
            <div className="container">
              <div className="row mt-2">
                <div className="col text-center">
                  <Alert color="danger">
                    <p className="mb-0">
                      Something went wrong! Please load the page
                    </p>
                  </Alert>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              color="success"
              onClick={() => {
                penaltyStartClick();
              }}
            >
              Yes! Continue
            </Button>
            <Button
              color="danger"
              onClick={() => {
                matchFinishClick();
              }}
            >
              No, Finish Match
            </Button>
          </>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={matchFinishClickModal}
        toggle={() => setMatchFinishClickModal(!matchFinishClickModal)}
        backdrop="static"
      >
        <ModalBody>
          Are you sure about finishing this Match?
          <div className="container">
            <div className="row mt-2">
              <div className="col text-center">
                <Alert color="info">
                  {matchWinState[0] === "DRAW" ? (
                    <p className="mb-0">This match is draw</p>
                  ) : (
                    <p className="mb-0">
                      {matchWinState[1]?.name} won this Match
                    </p>
                  )}
                </Alert>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              color="success"
              onClick={() => {
                matchFinishEndPoint();
              }}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => setMatchFinishClickModal(false)}
            >
              Cancel
            </Button>
          </>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default LiveScoringPage;
