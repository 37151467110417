import React from "react";

import { Icon } from "semantic-ui-react";

const TextIcon = ({ color, name, hideText, children }) => (
  <div style={{ whiteSpace: "nowrap", display: "inline-flex" }}>
    <Icon size="large" color={color} name={name} />
    <div
      className="sub-4-text"
      style={{
        alignSelf: "center",
        paddingLeft: "4px",
      }}
      hidden={hideText}
    >
      {children}
    </div>
  </div>
);

export default TextIcon;
