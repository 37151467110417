import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { addPlayerToTeam } from "../../../../api/teamUserAPI";
import CurrentPlayersBox from "../../../../components/CurrentPlayersBox/CurrentPlayersBox";

import "./AddPlayerToTeamForm.css";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      placeholderText="Contract start Date"
      dateFormat="yyyy-MM-dd"
      withPortal
      showYearDropdown
      scrollableYearDropdown
    />
  );
};

const PlayerSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <CurrentPlayersBox
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const AddPlayerToTeamForm = ({ setOpenAddPlayerTeamModal, teamId }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenAddPlayerTeamModal(false);
    window.location.reload();
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                player: "",
                startDate: "",
              }}
              validationSchema={Yup.object({
                player: Yup.string().required("Required"),
                startDate: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addPlayerToTeam({
                  userId: values.player,
                  teamId: teamId,
                  startDate: moment(values.startDate).format("YYYY-MM-DD"),
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <PlayerSelectDropdown
                            className="form-control"
                            name="player"
                          />
                          <ErrorMessage name="player">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <DatePickerField
                            className="form-control"
                            name="startDate"
                          />
                          <ErrorMessage name="startDate">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Add Player to Team
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlayerToTeamForm;
