import React, { useEffect, useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { editUserEnd, readByUserId } from "../../../../api/authAPI";
import renderUserLevel from "../../../../meta/renderUserLevel";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";

import "./EditUser.css";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      maxDate={new Date()}
      placeholderText="Birth Date"
      dateFormat="yyyy-MM-dd"
      withPortal
      showYearDropdown
      scrollableYearDropdown
    />
  );
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EditUser = ({ userId }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isUserCreateSuccess, setIsUserCreateSuccess] = useState(false);
  const [user, setUser] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readByUserId({ userId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setUser(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [user?.userId, userId]);

  function successSubmission(data) {
    localStorage.setItem("h-kck-auth-firstName", data.firstName);
    localStorage.setItem("h-kck-auth-lastName", data.lastName);
    localStorage.setItem("h-kck-auth-email", data.email);
    localStorage.setItem("h-kck-auth-token", data.token);
    localStorage.setItem("h-kck-auth-role", data.role);
    localStorage.setItem("h-kck-is-valid-auth", true);

    setIsUserCreateSuccess(true);
    window.location.href = "/";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12">
              <div className="edit-form-div">
                <Formik
                  enableReinitialize
                  initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    birthDate: user?.birthDate,
                    phoneNumber: user?.phoneNumber,
                    password: "",
                    newPassword: "",
                    confirmNewPassword: "",
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string()
                      .min(2, "FirstName cannot smaller than 2 characters")
                      .max(50, "FirstName cannot exceed 50 characters")
                      .required("Required"),
                    lastName: Yup.string()
                      .min(2, "LastName cannot smaller than 2 characters")
                      .max(50, "LastName cannot exceed 50 characters")
                      .required("Required"),
                    email: Yup.string()
                      .max(100, "Email cannot exceed 100 characters")
                      .email("Invalid Email")
                      .required("Required"),
                    phoneNumber: Yup.string().matches(
                      phoneRegExp,
                      "Phone number is not valid"
                    ),
                    password: Yup.string()
                      .min(2, "Password cannot smaller than 2 characters")
                      .max(60, "Password cannot exceed 60 characters"),
                    newPassword: Yup.string()
                      .min(2, "Password cannot smaller than 2 characters")
                      .max(60, "Password cannot exceed 60 characters"),
                    confirmNewPassword: Yup.string().when("newPassword", {
                      is: (val) => (val && val.length > 0 ? true : false),
                      then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both password need to be the same"
                      ),
                    }),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setIsLoading(true);
                    setIsFailSubmission(false);

                    editUserEnd({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      password: values.password,
                      birthDate: moment(values.birthDate).format("YYYY-MM-DD"),
                      phoneNumber: values.phoneNumber,
                      newPassword: values.newPassword,
                      confirmNewPassword: values.confirmNewPassword,
                    }).then((data) => {
                      try {
                        if (data["result"]) {
                          if (data["result"] === "SUCCESS") {
                            successSubmission(data["data"]);
                          } else if (data["result"] === "FAILED") {
                            failSubmission(
                              "Error!",
                              data["data"]["errorMessage"]
                            );
                          } else {
                            failSubmission("Error!", "Please try again later!");
                          }
                        } else {
                          failSubmission("Error!", "Please try again later!");
                        }
                      } catch (error) {
                        failSubmission("Error!", "Please try again later!");
                      }
                    });
                  }}
                >
                  <Form>
                    <div className="row d-flex justify-content-center">
                      <div className="col-sm-12 mb-4">
                        <div className="row">
                          <div className="col-sm-12 mb-4 mt-5">
                            <h5 className="head-text">Edit User Details</h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="firstName"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="firstName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="lastName"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="lastName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="email"
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                disabled={true}
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <DatePickerField
                                className="form-control"
                                name="birthDate"
                              />
                              <ErrorMessage name="birthDate">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="phoneNumber"
                                type="number"
                                className="form-control"
                                placeholder="Phone Number"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="phoneNumber">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            {renderUserLevel(
                              localStorage.getItem("h-kck-auth-role")
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-4 mt-5">
                            <h5 className="head-text-invoice">
                              Change Password
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="password">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="newPassword"
                                type="password"
                                className="form-control"
                                placeholder="New Password"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="newPassword">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-box">
                              <Field
                                name="confirmNewPassword"
                                type="password"
                                className="form-control"
                                placeholder="Confirm New Password"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="confirmNewPassword">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        {isUserCreateSuccess && (
                          <div className="row">
                            <div className="col text-center">
                              <>
                                <Alert color="success">
                                  <p>Successfully User Updated</p>
                                  {/* <hr />
                              <p className="mb-0">
                                New Your Successfully Created
                              </p> */}
                                </Alert>
                              </>
                            </div>
                          </div>
                        )}
                        {isFailSubmission && (
                          <div className="container">
                            <div className="row">
                              <div className="col text-center">
                                <>
                                  <Alert color="danger">
                                    {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                    <p className="mb-0">{errorMsg.sub}</p>
                                  </Alert>
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-12 text-center">
                              <button
                                type="submit"
                                className="button-submit-style"
                                disabled={isLoading}
                              >
                                Confirm Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
};

export default EditUser;
