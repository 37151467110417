import { AiFillTrophy } from "react-icons/ai";
import moment from "moment";
import { useEffect, useState } from "react";

import "./MatchLiveCard.css";

function MatchLiveCard({ match }) {
  console.log(match);
  let [teamOneNumP, setTeamOneNumP] = useState(0);
  let [teamTwoNumP, setTeamTwoNumP] = useState(0);

  useEffect(() => {
    if (match?.scoreCard?.teamOnePenalties) {
      let tempTeamOneNumP = 0;
      (match?.scoreCard?.teamOnePenalties).split("").forEach((character) => {
        if (character === "S") {
          tempTeamOneNumP = tempTeamOneNumP + 1;
          setTeamOneNumP(tempTeamOneNumP);
        }
      });
    }

    if (match?.scoreCard?.teamTwoPenalties) {
      let tempTeamTwoNumP = 0;
      (match?.scoreCard?.teamTwoPenalties).split("").forEach((character) => {
        if (character === "S") {
          tempTeamTwoNumP = tempTeamTwoNumP + 1;
          setTeamTwoNumP(tempTeamTwoNumP);
        }
      });
    }
  }, []);

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <div className="match-card-live">
      <span className="ui red label">LIVE</span>
      <span className="ui yellow label">
        QUARTER {match?.scoreCard?.currentQuarter}
      </span>
      <span className="ui blue label">
        {millisToMinutesAndSeconds(match?.scoreCard?.currentQuarterTime)}
      </span>
      <p>
        <a href={`/match/${match.matchId}`} className="a-tab-border-bottom">
          {match?.name}
        </a>
        , {match?.location}, {moment(match?.startTime).format("MMM Do YYYY")},{" "}
        <a
          href={`/tournament/${match.tournament.tournamentId}/`}
          className="a-tab-border-bottom"
        >
          {match?.tournament?.name}
        </a>
      </p>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamOne?.path}`}>
          <h4>{match?.teamOne?.name}</h4>
        </a>
        {match?.teamOne?.teamId === match?.winner?.teamId && (
          <AiFillTrophy size={20} />
        )}
        <span className="ui green label">
          G - {match?.scoreCard?.teamOneGoals}
        </span>
        {match?.scoreCard?.penaltyStarted === "YES" && (
          <span className="ui orange label">P - {teamOneNumP}</span>
        )}
      </div>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamTwo?.path}`}>
          <h4>{match?.teamTwo?.name}</h4>
        </a>
        {match?.teamTwo?.teamId === match?.winner?.teamId && (
          <AiFillTrophy size={20} />
        )}
        <span className="ui green label">
          G - {match?.scoreCard?.teamTwoGoals}
        </span>
        {match?.scoreCard?.penaltyStarted === "YES" && (
          <span className="ui orange label">P - {teamTwoNumP}</span>
        )}
      </div>
      <p className="match-yet">Ongoing Match</p>
      <div className="match-btn-section">
        <a className="match-summary" href={`/match/${match.matchId}`}>
          Summary
        </a>
        <a
          className="match-summary"
          href={`/live-scoring/${match.scoreCard.scoreCardId}/`}
        >
          Scorecard
        </a>
        <a
          className="match-summary"
          href={`/tournament/${match.tournament.tournamentId}/`}
        >
          Series Home
        </a>
      </div>
    </div>
  );
}

export default MatchLiveCard;
