import React, { useState } from "react";

import { Icon, Image, Menu } from "semantic-ui-react";

import "./TopMenu.css";

function TopMenu({ handleCollapse, handleProfileOpen }) {
  const userName =
    localStorage.getItem("h-kck-auth-firstName") +
    " " +
    localStorage.getItem("h-kck-auth-lastName");
  const [toggle, setToggle] = useState(false);

  const toggleCollapse = () => {
    setToggle(!toggle);
    handleCollapse && handleCollapse();
  };

  return (
    <Menu fixed="top" className="top-menu">
      {toggle ? (
        <Menu.Item className="logo-space-menu-item-collapse">
          <div className="display-inline logo-space">
            <Image src={"/images/favicon.jpg"} />
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item className="logo-space-menu-item">
          <div className="display-inline logo-space">
            {/* <Image src="/images/favicon.jpg" /> */}
            Hockey Score Board
          </div>
        </Menu.Item>
      )}

      <Menu.Item className="no-border" onClick={toggleCollapse}>
        <Icon name="bars" />
      </Menu.Item>
      <Menu.Item className="no-border">
        {localStorage.getItem("h-kck-site-domain")}
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item
          className="no-border"
          position="right"
          onClick={handleProfileOpen}
        >
          <div className="display-inline">
            {/* <Image circular size={"mini"} src="/images/empty_profile.png" /> */}
            {userName && <p className="light-4-text">Hi {userName}</p>}
          </div>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}

export default TopMenu;
