import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  ModalFooter,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AiFillPlayCircle } from "react-icons/ai";

import { readMatchesById } from "../../../../api/matchAPI";
import { getPlayerListInMatch } from "../../../../api/matchPlayerAPI";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import renderMatchType, {
  renderMatchPenaltyNum,
  renderMatchQuarterNum,
  renderMatchQuarterTime,
  renderMatchStatus,
} from "../../../../meta/renderMatchBlocks";
import AddPlayersToTeamModal from "../../components/addPlayersToTeamModal/AddPlayersToTeamModal";
import requiredPlayerNumber from "../../../../meta/playerListConditionCheck";
import { createScoreCard } from "../../../../api/scoreCardAPI";

import "./MatchViewPage.css";

function MatchViewPage() {
  let { matchId } = useParams();
  const [match, setMatch] = useState(null);
  const [matchPlayerList, setMatchPlayerList] = useState(null);
  const [pending, setPending] = useState(true);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);
  const [openPlayerAddModal, setOpenPlayerAddModal] = useState(false);
  const [clickedPlayerIndex, setClickedPlayerIndex] = useState(false);
  const [clickedTeam, setClickedTeam] = useState("");
  const [matchStart, setMatchStart] = useState(true);
  const [matchStartWarningModal, setMatchStartWarningModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    sub: "",
    type: "danger",
  });
  const [scoreCardId, setScoreCardId] = useState("");

  const playerAddModalClick = (value, playerIndex) => {
    setClickedTeam(value);
    setOpenPlayerAddModal(true);
    setClickedPlayerIndex(playerIndex);
  };

  useEffect(() => {
    readMatchesById({ matchId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setMatch(data["data"]);
            setPending(false);

            getPlayerListInMatch({ matchId }).then((dataPlayer) => {
              try {
                if (dataPlayer["result"]) {
                  if (dataPlayer["result"] === "SUCCESS") {
                    const dataTeamOneToJson = JSON.parse(
                      dataPlayer["data"].teamOnePlayers.replace(
                        new RegExp("&quot;", "g"),
                        '"'
                      )
                    );
                    const dataTeamTwoToJson = JSON.parse(
                      dataPlayer["data"].teamTwoPlayers.replace(
                        new RegExp("&quot;", "g"),
                        '"'
                      )
                    );

                    setMatchPlayerList({
                      matchId: dataPlayer["data"].matchId,
                      teamOnePlayers: dataTeamOneToJson,
                      teamTwoPlayers: dataTeamTwoToJson,
                    });

                    if (
                      dataTeamOneToJson.length >=
                        requiredPlayerNumber(match?.type)[0] &&
                      dataTeamTwoToJson.length > 0
                    ) {
                      setMatchStart(false);
                    } else {
                      setMatchStart(true);
                    }

                    if (dataTeamOneToJson) {
                      setTeamOneDataPending(false);
                    } else {
                      setTeamOneDataPending(true);
                    }
                    if (dataTeamTwoToJson) {
                      setTeamTwoDataPending(false);
                    } else {
                      setTeamTwoDataPending(true);
                    }
                  } else if (dataPlayer["result"] === "FAILED") {
                    setTeamOneDataPending(true);
                    setTeamTwoDataPending(true);
                  } else {
                    setTeamOneDataPending(true);
                    setTeamTwoDataPending(true);
                  }
                } else {
                  setTeamOneDataPending(true);
                  setTeamTwoDataPending(true);
                }
              } catch (error) {
                setTeamOneDataPending(true);
                setTeamTwoDataPending(true);
              }
            });
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [pending, teamOneDataPending, teamTwoDataPending, matchId, match?.type]);

  const startThisMatchBtn = () => {
    createScoreCard({
      matchId: matchId,
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setMatchStartWarningModal(false);
            setScoreCardId(data["data"]["scoreCardId"]);
            window.location.href = `/live-scoring/${data["data"]["scoreCardId"]}`;
          } else if (data["result"] === "FAILED") {
            setMatchStartWarningModal(true);
            if (data["data"]) {
              if (
                data["data"]["errorMessage"] ===
                "Score card for this match exist"
              ) {
                setScoreCardId(data["data"]["scoreCardId"]);

                setErrorMsg({
                  sub: "Score card is already exist, Do you need to continue with it?",
                  type: "warning",
                });
              }
            } else {
              setErrorMsg({
                sub: "Please try again later!",
                type: "danger",
              });
            }
          } else {
            setMatchStartWarningModal(true);
            setErrorMsg({
              sub: "Please try again later!",
              type: "danger",
            });
          }
        } else {
          setMatchStartWarningModal(true);
          setErrorMsg({
            sub: "Please try again later!",
            type: "danger",
          });
        }
      } catch (error) {
        setMatchStartWarningModal(true);
        setErrorMsg({
          sub: "Please try again later!",
          type: "danger",
        });
      }
    });
  };

  return (
    <>
      {!pending && (
        <div className="container">
          {match?.status !== "DONE" && (
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="button"
                  className="player-start-match-btn"
                  onClick={() => {
                    startThisMatchBtn();
                  }}
                  disabled={matchStart}
                >
                  <AiFillPlayCircle /> Start this Match
                </button>
              </div>
            </div>
          )}
          <div className="row mt-1">
            <div className="col-sm-4">
              <div className="table_outer_div">
                <div className="col-lg-12 header-txt">
                  <h5>Match Details</h5>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{match?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Home Team</b>
                      </td>
                      <td>{match?.teamOne?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Away Team</b>
                      </td>
                      <td>{match?.teamTwo?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{match?.location}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tournament</b>
                      </td>
                      <td>{match?.tournament?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Match Scorer</b>
                      </td>
                      <td>{`${match?.scorer?.firstName} ${match?.scorer?.lastName}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>{match?.responsible}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Match Start at</b>
                      </td>
                      <td>{match?.startTime}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Created</b>
                      </td>
                      <td>{match?.created}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-lg-12 table-footer">
                  {renderMatchStatus(match?.status)}
                  {renderMatchType(match?.type)}
                  {renderMatchQuarterTime(match?.quarterTime)}
                  {renderMatchQuarterNum(match?.numQuarter)}
                  {renderMatchPenaltyNum(match?.numPenalty)}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              {!teamOneDataPending && (
                <div className="table_outer_div">
                  <div className="col-lg-12 header-txt">
                    <h5>{`${match?.teamOne?.name} Team`}</h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>NUMBER</th>
                        <th>PLAYER</th>
                        <th>ROLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchPlayerList &&
                        matchPlayerList.teamOnePlayers &&
                        matchPlayerList.teamOnePlayers.map(function (
                          player,
                          index
                        ) {
                          return (
                            <tr key={index}>
                              <td>{player?.tNumber}</td>
                              <td>{player?.userName}</td>
                              <td>{player?.role}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
              {!pending && match?.status !== "DONE" && (
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="button-add-style player-add-btn"
                      onClick={() => {
                        playerAddModalClick(match?.teamOne, "ONE");
                      }}
                    >
                      {`Add Players to ${match?.teamOne?.name} Team`}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-4">
              {!teamTwoDataPending && (
                <div className="table_outer_div">
                  <div className="col-lg-12 header-txt">
                    <h5>{`${match?.teamTwo?.name} Team`}</h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>NUMBER</th>
                        <th>PLAYER</th>
                        <th>ROLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchPlayerList &&
                        matchPlayerList.teamTwoPlayers &&
                        matchPlayerList.teamTwoPlayers.map(function (
                          player,
                          index
                        ) {
                          return (
                            <tr key={index}>
                              <td>{player?.tNumber}</td>
                              <td>{player?.userName}</td>
                              <td>{player?.role}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
              {!pending && match?.status !== "DONE" && (
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="button-add-style player-add-btn"
                      onClick={() => {
                        playerAddModalClick(match?.teamTwo, "TWO");
                      }}
                    >
                      {`Add Players to ${match?.teamTwo?.name} Team`}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            isOpen={openPlayerAddModal}
            toggle={() => setOpenPlayerAddModal(!openPlayerAddModal)}
            size="lg"
          >
            <ModalHeader
              className="modal-head"
              toggle={() => setOpenPlayerAddModal(!openPlayerAddModal)}
            >
              Add Players to {clickedTeam.name} Team
            </ModalHeader>
            <ModalBody>
              <AddPlayersToTeamModal
                matchId={matchId}
                matchType={match?.type}
                selectedPlayersInChangingTeam={
                  clickedPlayerIndex === "ONE"
                    ? matchPlayerList?.teamOnePlayers
                      ? matchPlayerList?.teamOnePlayers
                      : []
                    : matchPlayerList?.teamTwoPlayers
                    ? matchPlayerList?.teamTwoPlayers
                    : []
                }
                selectedPlayersInOtherTeam={
                  clickedPlayerIndex === "ONE"
                    ? matchPlayerList?.teamTwoPlayers
                      ? matchPlayerList?.teamTwoPlayers
                      : []
                    : matchPlayerList?.teamOnePlayers
                    ? matchPlayerList?.teamOnePlayers
                    : []
                }
                clickedTeamId={clickedTeam?.teamId}
                clickedPlayerIndex={clickedPlayerIndex}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={matchStartWarningModal}
            toggle={() => setMatchStartWarningModal(!matchStartWarningModal)}
            backdrop="static"
          >
            <ModalBody>
              <Alert color={errorMsg.type}>
                <p className="mb-0">{errorMsg.sub}</p>
              </Alert>
            </ModalBody>
            <ModalFooter>
              {errorMsg.type === "danger" && (
                <Button
                  color="success"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              )}
              {errorMsg.type === "warning" && (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      window.location.href = `/live-scoring/${scoreCardId}`;
                    }}
                  >
                    Yes! Continue
                  </Button>
                  <Button
                    onClick={() => {
                      setMatchStartWarningModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default MatchViewPage;
