import { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AiOutlinePlus } from "react-icons/ai";

import CreateMatch from "../../forms/createMatchForm/CreateMatchForm";
import AllLiveMatches from "../../components/allLiveMatches/AllLiveMatches";
import AllNotStartedMatches from "../../components/allNotStartedMatches/AllNotStartedMatches";
import AllDoneMatches from "../../components/allDoneMatches/AllDoneMatches";
import AllPostPonedMatches from "../../components/allPostPonedMatches/AllPostPonedMatches";

import "./AllMatches.css";

const AllMatches = () => {
  const [openCreateMatchModal, setOpenCreateMatchModal] = useState(false);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenCreateMatchModal(true);
            }}
          >
            <AiOutlinePlus /> Add a Match
          </button>
        </div>
      </div>
      <AllLiveMatches />
      <AllDoneMatches />
      <AllNotStartedMatches />
      <AllPostPonedMatches />
      <Modal
        isOpen={openCreateMatchModal}
        toggle={() => setOpenCreateMatchModal(!openCreateMatchModal)}
        size="lg"
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenCreateMatchModal(!openCreateMatchModal)}
        >
          Add Match
        </ModalHeader>
        <ModalBody>
          <CreateMatch setOpenCreateMatchModal={setOpenCreateMatchModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllMatches;
