export default function renderUserLevel(role) {
  switch (role) {
    case "ADMIN":
      return (
        <h3>
          <span className="ui orange label">Admin</span>
        </h3>
      );
    case "EDITOR":
      return (
        <h3>
          <span className="ui olive label">Editor</span>
        </h3>
      );
    case "REFEREE":
      return (
        <h3>
          <span className="ui yellow label">Referee</span>
        </h3>
      );
    case "MEMBER":
      return (
        <h3>
          <span className="ui red label">Member</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}
