import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  ModalFooter,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import { readTournamentsById } from "../../../../api/tournamentAPI";
import renderTournamentType, {
  renderTournamentStatus,
} from "../../../../meta/renderTournamentBlocks";

import "./ViewTournamentPage.css";
import MatchSchedule from "../../../match/components/matchSchedule/MatchSchedule";

function ViewTournamentPage() {
  let { tournamentId } = useParams();
  const [tournament, setTournament] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readTournamentsById({ tournamentId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTournament(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [pending, tournamentId, tournament?.type]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-4">
              <div className="table_outer_div">
                <div className="col-lg-12 header-txt">
                  <h5>Tournament Details</h5>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{tournament?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>{`${tournament?.responsible?.firstName} ${tournament?.responsible?.lastName}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tournament Start at</b>
                      </td>
                      <td>{tournament?.startDate}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Created</b>
                      </td>
                      <td>{tournament?.created}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-lg-12 table-footer">
                  {renderTournamentStatus(tournament?.status)}
                  {renderTournamentType(tournament?.type)}
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              {tournamentId && <MatchSchedule tournamentId={tournamentId} />}
            </div>
          </div>
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default ViewTournamentPage;
