import { useEffect, useState } from "react";
import { Alert, Table } from "reactstrap";

import { readGoalsInMatchByTeam } from "../../../../api/goalAPI";
import { getPlayerListInMatch } from "../../../../api/matchPlayerAPI";
import {
  getPlayerById,
  millisToMinutesAndSeconds,
  renderGoalType,
  renderPlayer,
} from "../../../../meta/other";

import "./GoalListInMatch.css";

function GoalListInMatch({ matchId, teamOne, teamTwo, scoreBoardItem }) {
  const [teamOneGoalPending, setTeamOneGoalPending] = useState(true);
  const [teamTwoGoalPending, setTeamTwoGoalPending] = useState(true);
  const [teamOneGoals, setTeamOneGoals] = useState([]);
  const [teamTwoGoals, setTeamTwoGoals] = useState([]);

  const [teamOnePlayerList, setTeamOnePlayerList] = useState([]);
  const [teamTwoPlayerList, setTeamTwoPlayerList] = useState([]);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);

  const [selectedTeam, setSelectedTeam] = useState(true);

  useEffect(() => {
    getPlayerListInMatch({ matchId }).then((dataPlayer) => {
      try {
        if (dataPlayer["result"]) {
          if (dataPlayer["result"] === "SUCCESS") {
            const dataTeamOneToJson = JSON.parse(
              dataPlayer["data"].teamOnePlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );
            const dataTeamTwoToJson = JSON.parse(
              dataPlayer["data"].teamTwoPlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );
            setTeamOnePlayerList(dataTeamOneToJson);
            setTeamTwoPlayerList(dataTeamTwoToJson);
          } else if (dataPlayer["result"] === "FAILED") {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          } else {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          }
        } else {
          setTeamOneDataPending(true);
          setTeamTwoDataPending(true);
        }
      } catch (error) {
        setTeamOneDataPending(true);
        setTeamTwoDataPending(true);
      }
    });
  }, [teamOneDataPending, teamTwoDataPending, matchId]);

  useEffect(() => {
    let teamOneId = teamOne?.teamId;
    let teamTwoId = teamTwo?.teamId;

    readGoalsInMatchByTeam({ matchId, teamId: teamOneId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeamOneGoals(data["data"]);
            setTeamOneGoalPending(false);
          } else if (data["result"] === "FAILED") {
            setTeamOneGoalPending(true);
          } else {
            setTeamOneGoalPending(true);
          }
        } else {
          setTeamOneGoalPending(true);
        }
      } catch (error) {
        setTeamOneGoalPending(true);
      }
    });
    readGoalsInMatchByTeam({ matchId, teamId: teamTwoId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeamTwoGoals(data["data"]);
            setTeamTwoGoalPending(false);
          } else if (data["result"] === "FAILED") {
            setTeamTwoGoalPending(true);
          } else {
            setTeamTwoGoalPending(true);
          }
        } else {
          setTeamTwoGoalPending(true);
        }
      } catch (error) {
        setTeamTwoGoalPending(true);
      }
    });
  }, [matchId, teamOne?.teamId, teamTwo?.teamId, scoreBoardItem]);

  return (
    <>
      <div className="container pt-3">
        <div className="row d-flex justify-content-center">
          <div className="col">
            <h3 className="mb-0">GOALS IN THE MATCH</h3>
          </div>
        </div>
        {selectedTeam && (
          <div className="row">
            <div className="col-sm-12 score-left-div">
              {!teamOneGoalPending && (
                <div className="table_outer_div_match">
                  <div className="col-lg-12 header-txt">
                    <h5>
                      <span className="ui blue label">{teamOne?.name}</span>
                      <span
                        className="ui gray label pointer-set"
                        onClick={() => {
                          setSelectedTeam(false);
                        }}
                      >
                        {teamTwo?.name}
                      </span>
                    </h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>GOALER</th>
                        <th>ASSIST</th>
                        <th>TYPE</th>
                        <th>QUARTER</th>
                        <th>TIME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamOneGoals.map(function (goal, index) {
                        const playerDetails = getPlayerById(
                          teamOnePlayerList,
                          goal?.player
                        )[0];
                        const assistPlayerDetails = getPlayerById(
                          teamOnePlayerList,
                          goal?.assistPlayer
                        )[0];
                        return (
                          <tr key={index}>
                            <td>
                              {renderPlayer(
                                playerDetails?.tNumber,
                                playerDetails?.userName
                              )}
                            </td>
                            <td>
                              {assistPlayerDetails &&
                                renderPlayer(
                                  assistPlayerDetails?.tNumber,
                                  assistPlayerDetails?.userName
                                )}
                            </td>
                            <td>{renderGoalType(goal?.goalType)}</td>
                            <td>
                              <span className="ui yellow label">
                                {goal?.quarter}
                              </span>
                            </td>
                            <td>
                              <span className="ui blue label">
                                {millisToMinutesAndSeconds(goal?.quarterTime)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        )}
        {!selectedTeam && (
          <div className="row">
            <div className="col-sm-12 score-left-div">
              {!teamTwoGoalPending && (
                <div className="table_outer_div_match">
                  <div className="col-lg-12 header-txt">
                    <h5>
                      <span
                        className="ui gray label pointer-set"
                        onClick={() => {
                          setSelectedTeam(true);
                        }}
                      >
                        {teamOne?.name}
                      </span>
                      <span className="ui blue label">{teamTwo?.name}</span>
                    </h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>GOALER</th>
                        <th>ASSIST</th>
                        <th>TYPE</th>
                        <th>QUARTER</th>
                        <th>TIME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamTwoGoals.map(function (goal, index) {
                        const playerDetails = getPlayerById(
                          teamTwoPlayerList,
                          goal?.player
                        )[0];
                        const assistPlayerDetails = getPlayerById(
                          teamTwoPlayerList,
                          goal?.assistPlayer
                        )[0];
                        return (
                          <tr key={index}>
                            <td>
                              {renderPlayer(
                                playerDetails?.tNumber,
                                playerDetails?.userName
                              )}
                            </td>
                            <td>
                              {assistPlayerDetails &&
                                renderPlayer(
                                  assistPlayerDetails?.tNumber,
                                  assistPlayerDetails?.userName
                                )}
                            </td>
                            <td>{renderGoalType(goal?.goalType)}</td>
                            <td>
                              <span className="ui yellow label">
                                {goal?.quarter}
                              </span>
                            </td>
                            <td>
                              <span className="ui blue label">
                                {millisToMinutesAndSeconds(goal?.quarterTime)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GoalListInMatch;
