import { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import { renderPenaltyList, penaltyBoxRender } from "../../meta/other";

import "./PenaltyBoard.css";

function PenaltyBoard({ scoreBoardItem, matchFinishClick }) {
  console.log(scoreBoardItem?.teamOne?.penalties);
  console.log(scoreBoardItem?.teamTwo?.penalties);
  const [teamOnePenaltyList, setTeamOnePenaltyList] = useState("");
  const [teamTwoPenaltyList, setTeamTwoPenaltyList] = useState("");

  useEffect(() => {
    setTeamOnePenaltyList(
      renderPenaltyList(scoreBoardItem?.teamOne?.penalties, 10)
    );
    setTeamTwoPenaltyList(
      renderPenaltyList(scoreBoardItem?.teamTwo?.penalties, 10)
    );
  }, [scoreBoardItem?.teamOne?.penalties, scoreBoardItem?.teamTwo?.penalties]);
  return (
    <>
      {scoreBoardItem && (
        <div className="penalty-board">
          <div className="container pt-4 pb-4">
            <div className="row">
              <div className="col-sm-12 mb-1 d-flex justify-content-center">
                <h3 className="team-head">Penalty Round</h3>
              </div>
            </div>
            <hr />
            <div className="row mb-3">
              <div className="col-sm-12">
                <h3 className="team-head">{scoreBoardItem?.teamOne?.name}</h3>
                <div className="penalty-div">
                  {teamOnePenaltyList &&
                    teamOnePenaltyList.map((value, index) => {
                      return penaltyBoxRender(value, index);
                    })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h3 className="team-head">{scoreBoardItem?.teamTwo?.name}</h3>
                <div className="penalty-div">
                  {teamTwoPenaltyList &&
                    teamTwoPenaltyList.map((value, index) => {
                      return penaltyBoxRender(value, index);
                    })}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12 mb-1 d-flex justify-content-center">
                <button
                  className="match-finish-btn"
                  onClick={() => matchFinishClick()}
                >
                  Finish this Match
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PenaltyBoard;
