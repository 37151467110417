import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import AddPenaltyForm from "../../forms/addPenaltyForm/AddPenaltyForm";

import "./AddPenalty.css";

function AddPenalty({ matchId, teams, penaltyAddSuccess, scoreCardId }) {
  const [openAddPenaltyModal, setOpenAddPenaltyModal] = useState(false);

  const addPenaltyBtnClick = () => {
    setOpenAddPenaltyModal(true);
  };

  return (
    <>
      <div
        className="add-penalty-div"
        onClick={() => {
          addPenaltyBtnClick();
        }}
      >
        <div className="container pt-5 pb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-6 score-left-div">
              <h1 className="team-head">Add a Penalty</h1>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openAddPenaltyModal}
        toggle={() => setOpenAddPenaltyModal(!openAddPenaltyModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenAddPenaltyModal(!openAddPenaltyModal)}
        >
          Add a Penalty
        </ModalHeader>
        <ModalBody>
          <AddPenaltyForm
            matchId={matchId}
            scoreCardId={scoreCardId}
            teams={teams}
            setOpenAddPenaltyModal={setOpenAddPenaltyModal}
            penaltyAddSuccess={penaltyAddSuccess}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddPenalty;
