import { useMemo } from "react";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { readTournaments } from "../../../../api/tournamentAPI";
import { AiOutlinePlus } from "react-icons/ai";
import CreateTournament from "../../forms/createTournamentForm/CreateTournamentForm";
import AllLiveTournaments from "../../components/allLiveTournaments/AllLiveTournaments";
import AllDoneTournaments from "../../components/allDoneTournaments/AllDoneTournaments";
import AllNotStartedTournaments from "../../components/allNotStartedTournaments/AllNotStartedTournaments";

import "./AllTournament.css";

const AllTournament = () => {
  const [tournamentItemsItems, setTournamentItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openCreateTournamentModal, setOpenCreateTournamentModal] =
    useState(false);

  useEffect(() => {
    readTournaments().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTournamentItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [tournamentItemsItems.length]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenCreateTournamentModal(true);
            }}
          >
            <AiOutlinePlus /> Add Tournaments
          </button>
        </div>
      </div>
      <AllLiveTournaments />
      <AllDoneTournaments />
      <AllNotStartedTournaments />
      <Modal
        isOpen={openCreateTournamentModal}
        toggle={() => setOpenCreateTournamentModal(!openCreateTournamentModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() =>
            setOpenCreateTournamentModal(!openCreateTournamentModal)
          }
        >
          Add Tournament
        </ModalHeader>
        <ModalBody>
          <CreateTournament
            setOpenCreateTournamentModal={setOpenCreateTournamentModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllTournament;
