import React, { useState } from "react";
import Async from "react-select/async";

import { getCurrentTournamentList } from "../../api/tournamentAPI";

import "./CurrentTournamentBox.css";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

function CurrentTournamentBox({ selectedOption, changeCurrentValue }) {
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  const handleChange = (value) => {
    if (value !== null) {
      setSelectedValue(value);
      changeCurrentValue(value);
    }
  };

  const loadOptions = (input, callback) => {
    getCurrentTournamentList(input).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            callback([{ label: "Tournament List", options: data["data"] }]);
          } else if (data["result"] === "FAILED") {
            callback([]);
          } else {
            callback([]);
          }
        } else {
          callback([]);
        }
      } catch (error) {
        callback([]);
      }
    });
  };

  return (
    <Async
      className="step-input-box"
      loadOptions={loadOptions}
      value={selectedValue}
      placeholder="Enter Tournament Name"
      formatGroupLabel={formatGroupLabel}
      onChange={(value) => {
        handleChange(value);
      }}
      cacheOptions={true}
      grouped
    />
  );
}

export default CurrentTournamentBox;
