import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { addPenalty } from "../../../../api/penaltyAPI";
import { getPlayerListInMatch } from "../../../../api/matchPlayerAPI";

import "./AddPenaltyForm.css";

const AddPenaltyForm = ({
  scoreCardId,
  setOpenAddPenaltyModal,
  matchId,
  teams,
  penaltyAddSuccess,
}) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [matchPlayerList, setMatchPlayerList] = useState(null);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);
  const [penaltyTeamPlayers, setPenaltyTeamPlayers] = useState(null);

  const [selectedTeam, setSelectedTeam] = useState(0);

  useEffect(() => {
    getPlayerListInMatch({ matchId }).then((dataPlayer) => {
      try {
        if (dataPlayer["result"]) {
          if (dataPlayer["result"] === "SUCCESS") {
            const dataTeamOneToJson = JSON.parse(
              dataPlayer["data"].teamOnePlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );
            const dataTeamTwoToJson = JSON.parse(
              dataPlayer["data"].teamTwoPlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );

            setMatchPlayerList({
              matchId: dataPlayer["data"].matchId,
              teamOnePlayers: dataTeamOneToJson,
              teamTwoPlayers: dataTeamTwoToJson,
            });
          } else if (dataPlayer["result"] === "FAILED") {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          } else {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          }
        } else {
          setTeamOneDataPending(true);
          setTeamTwoDataPending(true);
        }
      } catch (error) {
        setTeamOneDataPending(true);
        setTeamTwoDataPending(true);
      }
    });
  }, [teamOneDataPending, teamTwoDataPending, matchId]);

  const successSubmission = (status) => {
    setOpenAddPenaltyModal(false);
    penaltyAddSuccess(selectedTeam, status);
  };

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function teamChange(e) {
    if (e.target.options.selectedIndex === 1) {
      setPenaltyTeamPlayers(matchPlayerList?.teamOnePlayers);
      setSelectedTeam(1);
    } else if (e.target.options.selectedIndex === 2) {
      setPenaltyTeamPlayers(matchPlayerList?.teamTwoPlayers);
      setSelectedTeam(2);
    }
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                matchId: matchId,
                teamId: "",
                playerId: "",
                status: "SUCCESS",
              }}
              validationSchema={Yup.object({
                matchId: Yup.string().required("Required"),
                teamId: Yup.string().required("Required"),
                playerId: Yup.string().required("Required"),
                status: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addPenalty({
                  scoreCardId: scoreCardId,
                  matchId: values.matchId,
                  teamId: values.teamId,
                  playerId: values.playerId,
                  status: values.status,
                  selectedTeam: selectedTeam,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(values.status);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="status"
                            as="select"
                            className="form-control"
                            disabled={isLoading}
                          >
                            <option value="SUCCESS">SUCCESS</option>
                            <option value="MISSED">MISSED</option>
                          </Field>
                          <ErrorMessage name="goalType">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="teamId"
                            as="select"
                            className="form-control"
                            disabled={isLoading}
                            onClick={(e) => {
                              teamChange(e);
                            }}
                          >
                            <option value="" disabled selected>
                              Select Penalty scored Team
                            </option>
                            {teams.map((team, index) => (
                              <option value={team?.teamId} key={index}>
                                {team?.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="teamId">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {penaltyTeamPlayers && (
                      <>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="playerId"
                                as="select"
                                className="form-control"
                                disabled={isLoading}
                              >
                                <option value="" disabled selected>
                                  Select Penalty scorer
                                </option>
                                {penaltyTeamPlayers.map((player, index) => (
                                  <option value={player?.userId} key={index}>
                                    {`${player?.tNumber} | ${player?.userName}`}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name="playerId">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                      </>
                    )}
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Add this Penalty
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPenaltyForm;
