export default function renderTournamentType(type) {
  switch (type) {
    case "LEAGUE":
      return <span className="ui green label">League</span>;
    case "KNOCKOUT":
      return <span className="ui pink label">Knockout</span>;
    default:
      return <span className="ui teal label">Not Mention</span>;
  }
}

export function renderTournamentStatus(status) {
  switch (status) {
    case "LIVE":
      return <span className="ui green label">LIVE</span>;
    case "DONE":
      return <span className="ui blue label">DONE</span>;
    case "NOT YET":
      return <span className="ui violet label">NOT STARTED</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}
