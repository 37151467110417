import { UncontrolledTooltip } from "reactstrap";

export default function renderPlayingState(state) {
  const userId = "id";
  switch (state) {
    case "PLAYING":
      return (
        <>
          <h3>
            <span className="ui green label" id={userId}>
              P
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Playing
          </UncontrolledTooltip> */}
        </>
      );
    case "NOT PLAYING":
      return (
        <>
          <h3>
            <span className="ui black label" id={userId}>
              NP
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Not Playing
          </UncontrolledTooltip> */}
        </>
      );
    case "INJURED":
      return (
        <>
          <h3>
            <span className="ui red label" id={userId}>
              I
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Injured
          </UncontrolledTooltip> */}
        </>
      );
    default:
      return (
        <>
          <h3>
            <span className="ui teal label" id={userId}>
              NM
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Not Mention
          </UncontrolledTooltip> */}
        </>
      );
  }
}
