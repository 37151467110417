import axios from "axios";

export const createScoreCard = async ({ matchId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readScoreCardById = async ({ scoreCardId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("scoreCardId", scoreCardId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateScoreCardQuarter = async ({ scoreCardId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("scoreCardId", scoreCardId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/quarter_update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateScoreCardQuarterTime = async ({
  scoreCardId,
  quarterTime,
}) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("scoreCardId", scoreCardId);
    formData.append("quarterTime", quarterTime);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/quarter_time_update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateScoreCardPenaltyState = async ({ scoreCardId, state }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("scoreCardId", scoreCardId);
    formData.append("state", state);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/penalty_state_update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readScoreCardByMatchId = async ({ matchId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/scoreCard/read_score_card_id_by_match_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
