import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { readTeams } from "../../../api/teamAPI";
import { AiOutlinePlus } from "react-icons/ai";
import { MdViewHeadline } from "react-icons/md";
import CreateTeamForm from "../forms/createTeamForm/CreateTeamForm";

import "./AllTeams.css";
import { Link } from "react-router-dom";

const AllTeams = () => {
  const [teamItems, setTeamItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);

  useEffect(() => {
    readTeams().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeamItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [teamItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        grow: 3,
      },
      {
        name: "Responsible",
        selector: (row) => row.responsible,
        sortable: true,
        grow: 2,
      },
      {
        name: "Modified",
        selector: (row) => row.modified,
        sortable: true,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <Link to={"team/".concat(row.path)} className="">
              <MdViewHeadline size={20} />
            </Link>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenCreateTeamModal(true);
            }}
          >
            <AiOutlinePlus /> Add Team
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Teams"
              data={teamItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openCreateTeamModal}
        toggle={() => setOpenCreateTeamModal(!openCreateTeamModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenCreateTeamModal(!openCreateTeamModal)}
        >
          Add Team
        </ModalHeader>
        <ModalBody>
          <CreateTeamForm setOpenCreateTeamModal={setOpenCreateTeamModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllTeams;
