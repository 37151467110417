import { Label } from "semantic-ui-react";

export function renderGoalType(value) {
  switch (value) {
    case "PENALTY CORNER":
      return <span className="ui green label">PC</span>;
    case "FIELD GOAL":
      return <span className="ui pink label">FG</span>;
    case "PENALTY STROKES":
      return <span className="ui gray label">PS</span>;
    default:
      return <span className="ui black label">NM</span>;
  }
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function getPlayerById(object, playerId) {
  return object.filter((obj) => {
    return obj.userId === playerId;
  });
}

export function renderPlayer(tNumber, name, status) {
  return (
    <span style={{ minWidth: "220px" }} className="ui violet label">
      <span className="ui white circular label">{tNumber}</span> {name}
    </span>
  );
}

export function renderPenaltyPlayer(tNumber, name, status) {
  switch (status) {
    case "SUCCESS":
      return (
        <span style={{ minWidth: "250px" }} className="ui green label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
    case "MISSED":
      return (
        <span style={{ minWidth: "250px" }} className="ui red label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
    default:
      return (
        <span style={{ minWidth: "250px" }} className="ui violet label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
  }
}

export function penaltyBoxRender(value, index) {
  switch (value) {
    case "S":
      return <Label color="green">{index + 1}</Label>;
    case "F":
      return <Label color="red">{index + 1}</Label>;
    default:
      return <Label color="gray">{index + 1}</Label>;
  }
}

export function renderPenaltyList(object, number) {
  let emptyArr = "";

  if (object) {
    if (object.length < number) {
      for (let i = 0; i < number - object.length; i++) {
        emptyArr = emptyArr + "M";
      }
    }
  } else {
    for (let i = 0; i < number; i++) {
      emptyArr = emptyArr + "M";
    }
  }

  object = object + emptyArr;

  let pArr = Array.from(object);
  return pArr;
}

export function findMatchWinner(scoreBoardItem, scoreCardItem) {
  if (
    scoreBoardItem?.teamOne?.numOfGoals === scoreBoardItem?.teamTwo?.numOfGoals
  ) {
    let teamOneNumP = 0;
    let teamTwoNumP = 0;

    (scoreCardItem?.teamOnePenalties).split("").forEach((character) => {
      if (character === "S") {
        teamOneNumP = teamOneNumP + 1;
      }
    });

    (scoreCardItem?.teamTwoPenalties).split("").forEach((character) => {
      if (character === "S") {
        teamTwoNumP = teamTwoNumP + 1;
      }
    });

    if (teamOneNumP > teamTwoNumP) {
      return ["WINNER", scoreCardItem?.match?.teamOne];
    } else if (teamOneNumP < teamTwoNumP) {
      return ["WINNER", scoreCardItem?.match?.teamTwo];
    } else {
      return ["DRAW", "DRAW"];
    }
  } else if (
    scoreBoardItem?.teamOne?.numOfGoals > scoreBoardItem?.teamTwo?.numOfGoals
  ) {
    return ["WINNER", scoreCardItem?.match?.teamOne];
  } else {
    return ["WINNER", scoreCardItem?.match?.teamTwo];
  }
}
