import axios from "axios";

export const addPenalty = async ({
  scoreCardId,
  matchId,
  teamId,
  selectedTeam,
  playerId,
  status,
}) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("scoreCardId", scoreCardId);
    formData.append("selectedTeam", selectedTeam);
    formData.append("teamId", teamId);
    formData.append("playerId", playerId);
    formData.append("status", status);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/penalty/add.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readPenaltiesInMatchByTeam = async ({ matchId, teamId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("teamId", teamId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/penalty/read_penalty_in_match_by_team.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
