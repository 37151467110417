import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";

import ControlButtons from "./ControlButtons/ControlButtons";
import Timer from "./Timer/Timer";
import {
  renderMatchQuarterNumInNumeric,
  renderQuarterInMillisecondsTime,
} from "../../meta/renderMatchBlocks";
import { updateScoreCardQuarter } from "../../api/scoreCardAPI";

import "./StopWatch.css";

function StopWatch({
  currentQuarterTime,
  pauseBtnClick,
  isPaused,
  setIsPaused,
  time,
  setTime,
  quarterTime,
  numQuarter,
  currentQuarter,
  scoreCardId,
  quarterUpdateSuccess,
  setMatchFinishWarningModal,
  matchFinishClick,
  havePenalty,
}) {
  const [isActive, setIsActive] = useState(false);
  // const [isPaused, setIsPaused] = useState(true);
  // const [time, setTime] = useState(0);
  const [isFailSubmission, setIsFailSubmission] = useState(false);

  useEffect(() => {
    setTime(currentQuarterTime);
  }, [currentQuarterTime]);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handlePauseResume = () => {
    pauseBtnClick(time);
    setIsPaused(!isPaused);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);

    if (renderQuarterInMillisecondsTime(quarterTime) <= time) {
      if (renderMatchQuarterNumInNumeric(numQuarter) <= currentQuarter) {
        if (havePenalty) {
          setMatchFinishWarningModal(true);
        } else {
          matchFinishClick();
        }
      } else {
        updateScoreCardQuarter({ scoreCardId }).then((data) => {
          try {
            if (data["result"]) {
              if (data["result"] === "SUCCESS") {
                quarterUpdateSuccess();
                setIsFailSubmission(false);
              } else if (data["result"] === "FAILED") {
                setIsFailSubmission(true);
              } else {
                setIsFailSubmission(true);
              }
            } else {
              setIsFailSubmission(true);
            }
          } catch (error) {
            setIsFailSubmission(true);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="stop-watch">
        <Timer time={time} />
        <ControlButtons
          active={isActive}
          isPaused={isPaused}
          handleStart={handleStart}
          handlePauseResume={handlePauseResume}
          handleReset={handleReset}
          quarterTime={quarterTime}
          numQuarter={numQuarter}
          currentQuarter={currentQuarter}
          time={time}
        />
      </div>
      {isFailSubmission && (
        <div className="container">
          <div className="row mt-2">
            <div className="col text-center">
              <Alert color="danger">
                <p className="mb-0">
                  Something went wrong! Please load the page
                </p>
              </Alert>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StopWatch;
