import { Label } from "semantic-ui-react";

import "./ScoreBoard.css";

function ScoreBoard({ scoreBoardItem }) {
  return (
    <>
      {scoreBoardItem && (
        <div className="score-board">
          <div className="container pt-4 pb-4">
            <div className="row">
              <div className="col-sm-12 mb-3 d-flex justify-content-center">
                <h3 className="team-head">{scoreBoardItem?.tournamentName}</h3>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6 score-left-div">
                <h1 className="team-head">{scoreBoardItem?.teamOne?.name}</h1>
                <div className="goal-number">
                  <Label color="blue">
                    {scoreBoardItem?.teamOne?.numOfGoals}
                  </Label>
                </div>
              </div>
              <div className="col-sm-6 score-left-div">
                <h1 className="team-head">{scoreBoardItem?.teamTwo?.name}</h1>
                <div className="goal-number">
                  <Label color="blue">
                    {scoreBoardItem?.teamTwo?.numOfGoals}
                  </Label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-12 pt-5 pb-2 d-flex justify-content-center">
                    <h2 className="team-head">SHOTS ON GOAL</h2>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-4 d-flex justify-content-center">
                    <div className="shot-number">
                      <Label color="yellow">
                        {scoreBoardItem?.teamOne?.numOfShots}
                      </Label>
                    </div>
                  </div>
                  <div className="col-sm-4 d-flex justify-content-center">
                    <div className="shot-number">
                      <Label color="yellow">
                        {scoreBoardItem?.teamTwo?.numOfShots}
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-12 pt-5 pb-2 d-flex justify-content-center">
                    <h2 className="team-head">QUARTER</h2>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-4 d-flex justify-content-center">
                    <div className="shot-number">
                      <Label color="violet">{scoreBoardItem?.period}</Label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ScoreBoard;
