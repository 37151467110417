import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { createMatch } from "../../../../api/matchAPI";
import CurrentTournamentBox from "../../../../components/CurrentTournamentBox/CurrentTournamentBox";
import CurrentScorerBox from "../../../../components/CurrentScorerBox/CurrentScorerBox";
import PlayingTeamsBox from "../../../../components/PlayingTeamsBox/PlayingTeamsBox";

import "./CreateMatchForm.css";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      minDate={new Date()}
      placeholderText="Match Start Time"
      showTimeSelect
      dateFormat="yyyy-MM-dd h:mm aa"
      withPortal
      showYearDropdown
      scrollableYearDropdown
      timeIntervals={5}
    />
  );
};

const TournamentSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <CurrentTournamentBox
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const TeamSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <PlayingTeamsBox
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
      placeholderSet={props.placeholderSet}
    />
  );
};

const ScorerSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <CurrentScorerBox
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const CreateMatchForm = ({ setOpenCreateMatchModal }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenCreateMatchModal(false);
    window.location.href = "/match";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                name: "",
                teamOneId: "",
                teamTwoId: "",
                type: "11S",
                quarterTime: "15MIN",
                numQuarter: "FOUR",
                numPenalty: "NO",
                location: "",
                tournamentId: "",
                scorerId: "",
                startTime: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(5, "Name cannot smaller than 5 characters")
                  .max(130, "Name cannot exceed 130 characters")
                  .required("Required"),
                type: Yup.string().required("Required"),
                teamOneId: Yup.string().required("Required"),
                teamTwoId: Yup.string().required("Required"),
                quarterTime: Yup.string().required("Required"),
                numQuarter: Yup.string().required("Required"),
                numPenalty: Yup.string().required("Required"),
                location: Yup.string()
                  .min(4, "Location cannot smaller than 4 characters")
                  .max(100, "Location cannot exceed 100 characters")
                  .required("Required"),
                tournamentId: Yup.string().required("Required"),
                scorerId: Yup.string().required("Required"),
                startTime: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                createMatch({
                  name: values.name,
                  type: values.type,
                  teamOneId: values.teamOneId,
                  teamTwoId: values.teamTwoId,
                  quarterTime: values.quarterTime,
                  numQuarter: values.numQuarter,
                  numPenalty: values.numPenalty,
                  location: values.location,
                  tournamentId: values.tournamentId,
                  scorerId: values.scorerId,
                  startTime: moment(values.startTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Match Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="name">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <TeamSelectDropdown
                            className="form-control"
                            name="teamOneId"
                            placeholderSet="Enter Home Team"
                          />
                          <ErrorMessage name="teamOneId">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <TeamSelectDropdown
                            className="form-control"
                            name="teamTwoId"
                            placeholderSet="Enter Away Team"
                          />
                          <ErrorMessage name="teamTwoId">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="type"
                            as="select"
                            className="form-control"
                            placeholder="Select Match Type"
                            disabled={isLoading}
                          >
                            <option value="6S">SIX A SIDE (6S)</option>
                            <option value="7S">SEVEN A SIDE (7S)</option>
                            <option value="11S">ELEVEN A SIDE (11S)</option>
                          </Field>
                          <ErrorMessage name="type">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="numPenalty"
                            as="select"
                            className="form-control"
                            placeholder="Select Penalty Number"
                            disabled={isLoading}
                          >
                            <option value="ONE">ONE PENALTY</option>
                            <option value="TWO">TWO PENALTIES</option>
                            <option value="THREE">THREE PENALTIES</option>
                            <option value="FOUR">FOUR PENALTIES</option>
                            <option value="FIVE">FIVE PENALTIES</option>
                            <option value="SIX">SIX PENALTIES</option>
                            <option value="11S">NO PENALTIES</option>
                          </Field>
                          <ErrorMessage name="numPenalty">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="quarterTime"
                            as="select"
                            className="form-control"
                            placeholder="Select quarter Time"
                            disabled={isLoading}
                          >
                            <option value="5MIN">5 MIN PER QUARTER</option>
                            <option value="10MIN">10 MIN PER QUARTER</option>
                            <option value="15MIN">15 MIN PER QUARTER</option>
                            <option value="20MIN">20 MIN PER QUARTER</option>
                          </Field>
                          <ErrorMessage name="quarterTime">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="numQuarter"
                            as="select"
                            className="form-control"
                            placeholder="Select Quarter Number"
                            disabled={isLoading}
                          >
                            <option value="ONE">ONE QUARTER</option>
                            <option value="TWO">TWO QUARTER</option>
                            <option value="THREE">THREE QUARTER</option>
                            <option value="FOUR">FOUR QUARTER</option>
                          </Field>
                          <ErrorMessage name="numQuarter">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="location"
                            type="text"
                            className="form-control"
                            placeholder="Match Location"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="location">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <TournamentSelectDropdown
                            className="form-control"
                            name="tournamentId"
                          />
                          <ErrorMessage name="tournamentId">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <ScorerSelectDropdown
                            className="form-control"
                            name="scorerId"
                          />
                          <ErrorMessage name="scorerId">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <DatePickerField
                            className="form-control"
                            name="startTime"
                          />
                          <ErrorMessage name="startTime">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Create New Match
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMatchForm;
