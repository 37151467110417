import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";

import { getPlayerListInMatch } from "../../../../api/matchPlayerAPI";
import { readScoreCardById } from "../../../../api/scoreCardAPI";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import { penaltyBoxRender } from "../../../../meta/other";
import renderMatchType, {
  renderMatchPenaltyNum,
  renderMatchQuarterNum,
  renderMatchQuarterTime,
  renderMatchStatus,
} from "../../../../meta/renderMatchBlocks";
import GoalListInMatch from "../../../scoring/components/goalListInMatch/GoalListInMatch";
import PenaltyListInMatch from "../../components/penaltyListIInMatch/PenaltyListInMatch";

import "./FinishedMatchScore.css";

function FinishedMatchScore() {
  let { scoreCardId } = useParams();
  const [matchPlayerList, setMatchPlayerList] = useState(null);
  const [pending, setPending] = useState(true);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);
  const [matchId, setMatchId] = useState("");
  const [scoreCardItem, setScoreCardItem] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(true);

  useEffect(() => {
    getPlayerListInMatch({ matchId }).then((dataPlayer) => {
      try {
        if (dataPlayer["result"]) {
          if (dataPlayer["result"] === "SUCCESS") {
            const dataTeamOneToJson = JSON.parse(
              dataPlayer["data"].teamOnePlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );
            const dataTeamTwoToJson = JSON.parse(
              dataPlayer["data"].teamTwoPlayers.replace(
                new RegExp("&quot;", "g"),
                '"'
              )
            );

            setMatchPlayerList({
              matchId: dataPlayer["data"].matchId,
              teamOnePlayers: dataTeamOneToJson,
              teamTwoPlayers: dataTeamTwoToJson,
            });

            if (dataTeamOneToJson) {
              setTeamOneDataPending(false);
            } else {
              setTeamOneDataPending(true);
            }
            if (dataTeamTwoToJson) {
              setTeamTwoDataPending(false);
            } else {
              setTeamTwoDataPending(true);
            }
          } else if (dataPlayer["result"] === "FAILED") {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          } else {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          }
        } else {
          setTeamOneDataPending(true);
          setTeamTwoDataPending(true);
        }
      } catch (error) {
        setTeamOneDataPending(true);
        setTeamTwoDataPending(true);
      }
    });
  }, [pending, teamOneDataPending, teamTwoDataPending, matchId]);

  useEffect(() => {
    readScoreCardById({ scoreCardId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setScoreCardItem(data["data"]);
            if (data["data"]?.match?.status !== "DONE") {
              window.location.href = `/match/${data["data"]?.match?.matchId}`;
            }

            setMatchId(data["data"].match.matchId);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            window.location.href = `/`;
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [scoreCardId]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="table_outer_div">
                    <div className="col-lg-12 header-txt">
                      <h5>Match Details</h5>
                    </div>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <b>Name</b>
                          </td>
                          <td>{scoreCardItem?.match?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Home Team</b>
                          </td>
                          <td>{scoreCardItem?.match?.teamOne?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Away Team</b>
                          </td>
                          <td>{scoreCardItem?.match?.teamTwo?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Location</b>
                          </td>
                          <td>{scoreCardItem?.match?.location}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Tournament</b>
                          </td>
                          <td>{scoreCardItem?.match?.tournament?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Match Scorer</b>
                          </td>
                          <td>{`${scoreCardItem?.match?.scorer?.firstName} ${scoreCardItem?.match?.scorer?.lastName}`}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Responsible</b>
                          </td>
                          <td>{scoreCardItem?.match?.responsible}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Match Start at</b>
                          </td>
                          <td>{scoreCardItem?.match?.startTime}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Created</b>
                          </td>
                          <td>{scoreCardItem?.match?.created}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="col-lg-12 table-footer">
                      {renderMatchStatus(scoreCardItem?.match?.status)}
                      {renderMatchType(scoreCardItem?.match?.type)}
                      {renderMatchQuarterTime(
                        scoreCardItem?.match?.quarterTime
                      )}
                      {renderMatchQuarterNum(scoreCardItem?.match?.numQuarter)}
                      {renderMatchPenaltyNum(scoreCardItem?.match?.numPenalty)}
                    </div>
                  </div>
                </div>
              </div>
              {selectedTeam && (
                <div className="row">
                  <div className="col-sm-12">
                    {!teamOneDataPending && (
                      <div className="table_outer_div">
                        <div className="col-lg-12 header-txt">
                          <span className="ui blue label">
                            {scoreCardItem?.match?.teamOne?.name}
                          </span>
                          <span
                            className="ui gray label pointer-set"
                            onClick={() => {
                              setSelectedTeam(false);
                            }}
                          >
                            {scoreCardItem?.match?.teamTwo?.name}
                          </span>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>NUMBER</th>
                              <th>PLAYER</th>
                              <th>ROLE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchPlayerList &&
                              matchPlayerList.teamOnePlayers &&
                              matchPlayerList.teamOnePlayers.map(function (
                                player,
                                index
                              ) {
                                return (
                                  <tr key={index}>
                                    <td>{player?.tNumber}</td>
                                    <td>{player?.userName}</td>
                                    <td>{player?.role}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!selectedTeam && (
                <div className="row">
                  <div className="col-sm-12">
                    {!teamTwoDataPending && (
                      <div className="table_outer_div">
                        <div className="col-lg-12 header-txt">
                          <span
                            className="ui gray label pointer-set"
                            onClick={() => {
                              setSelectedTeam(true);
                            }}
                          >
                            {scoreCardItem?.match?.teamOne?.name}
                          </span>
                          <span className="ui blue label">
                            {scoreCardItem?.match?.teamTwo?.name}
                          </span>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>NUMBER</th>
                              <th>PLAYER</th>
                              <th>ROLE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchPlayerList &&
                              matchPlayerList.teamTwoPlayers &&
                              matchPlayerList.teamTwoPlayers.map(function (
                                player,
                                index
                              ) {
                                return (
                                  <tr key={index}>
                                    <td>{player?.tNumber}</td>
                                    <td>{player?.userName}</td>
                                    <td>{player?.role}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-8 finished-score-board">
              <div className="row">
                <div className="col-sm-12">
                  <div className="table_outer_div table_outer_div_winner">
                    <div className="col-lg-12 header-txt">
                      {scoreCardItem?.match?.winner === "DRAW" ? (
                        <h5>Match is tied</h5>
                      ) : (
                        <h5>
                          {scoreCardItem?.match?.winner?.name} won this match
                        </h5>
                      )}
                    </div>
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>GOALS</th>
                          <th>SHOTS</th>
                          {scoreCardItem?.penaltyStarted === "YES" && (
                            <th>PENALTIES</th>
                          )}
                          <th>CARDS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>{scoreCardItem?.match?.teamOne?.name}</b>
                          </td>
                          <td>{scoreCardItem?.teamOneGoals}</td>
                          <td>{scoreCardItem?.teamOneShots}</td>
                          {scoreCardItem?.penaltyStarted === "YES" && (
                            <td>
                              {scoreCardItem?.teamOnePenalties &&
                                Array.from(scoreCardItem?.teamOnePenalties).map(
                                  (value, index) => {
                                    return penaltyBoxRender(value, index);
                                  }
                                )}
                            </td>
                          )}
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{scoreCardItem?.match?.teamTwo?.name}</b>
                          </td>
                          <td>{scoreCardItem?.teamTwoGoals}</td>
                          <td>{scoreCardItem?.teamTwoShots}</td>
                          {scoreCardItem?.penaltyStarted === "YES" && (
                            <td>
                              {scoreCardItem?.teamTwoPenalties &&
                                Array.from(scoreCardItem?.teamTwoPenalties).map(
                                  (value, index) => {
                                    return penaltyBoxRender(value, index);
                                  }
                                )}
                            </td>
                          )}
                          <td>-</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              <GoalListInMatch
                matchId={scoreCardItem?.match?.matchId}
                teamOne={scoreCardItem?.match?.teamOne}
                teamTwo={scoreCardItem?.match?.teamTwo}
                scoreBoardItem=""
              />

              {scoreCardItem?.penaltyStarted === "YES" && (
                <PenaltyListInMatch
                  matchId={scoreCardItem?.match?.matchId}
                  teamOne={scoreCardItem?.match?.teamOne}
                  teamTwo={scoreCardItem?.match?.teamTwo}
                  scoreBoardItem=""
                />
              )}
            </div>
          </div>
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default FinishedMatchScore;
