export default function renderMatchType(type) {
  switch (type) {
    case "6S":
      return <span className="ui red label">6S</span>;
    case "7S":
      return <span className="ui orange label">7S</span>;
    case "11S":
      return <span className="ui blue label">11S</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}

export function renderMatchStatus(status) {
  switch (status) {
    case "LIVE":
      return <span className="ui green label">LIVE</span>;
    case "DONE":
      return <span className="ui blue label">DONE</span>;
    case "POSTPONED":
      return <span className="ui red label">POSTPONED</span>;
    case "NOT YET":
      return <span className="ui violet label">NOT STARTED</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}

export function renderMatchQuarterTime(value) {
  switch (value) {
    case "5MIN":
      return <span className="ui violet label">5MIN</span>;
    case "10MIN":
      return <span className="ui purple label">10MIN</span>;
    case "15MIN":
      return <span className="ui pink label">15MIN</span>;
    case "20MIN":
      return <span className="ui brown label">20MIN</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}

export function renderMatchQuarterNum(value) {
  switch (value) {
    case "ONE":
      return <span className="ui grey label">One QUARTER</span>;
    case "TWO":
      return <span className="ui pink label">TWO QUARTER</span>;
    case "THREE":
      return <span className="ui red label">THREE QUARTER</span>;
    case "FOUR":
      return <span className="ui blue label">FOUR QUARTER</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}

export function renderMatchPenaltyNum(value) {
  switch (value) {
    case "ONE":
      return <span className="ui green label">1 PENALTIES</span>;
    case "TWO":
      return <span className="ui pink label">2 PENALTIES</span>;
    case "THREE":
      return <span className="ui red label">3 PENALTIES</span>;
    case "FOUR":
      return <span className="ui brown label">4 PENALTIES</span>;
    case "FIVE":
      return <span className="ui blue label">5 PENALTIES</span>;
    case "SIX":
      return <span className="ui orange label">6 PENALTIES</span>;
    case "NO":
      return <span className="ui gray label">NO PENALTIES</span>;
    default:
      return <span className="ui black label">Not Mention</span>;
  }
}

export function renderQuarterInMillisecondsTime(value) {
  switch (value) {
    case "5MIN":
      return 5 * 60000;
    case "10MIN":
      return 10 * 60000;
    case "15MIN":
      return 15 * 60000;
    case "20MIN":
      return 20 * 60000;
    default:
      return 20 * 60000;
  }
}

export function renderMatchQuarterNumInNumeric(value) {
  switch (value) {
    case "ONE":
      return 1;
    case "TWO":
      return 2;
    case "THREE":
      return 3;
    case "FOUR":
      return 4;
    default:
      return 5;
  }
}
