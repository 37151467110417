import React from "react";
import { Link } from "react-router-dom";
import { FaUserAlt, FaListAlt } from "react-icons/fa";

import "./homePage.css";

function HomePage() {
  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className="col-sm-12">
          <div>
            {localStorage.getItem("h-kck-auth-role") === "ADMIN" && (
              <div className="row d-flex justify-content-center">
                <div className="col-sm-4">
                  <Link to="/user">
                    <div className="card-h-div p-4 mb-4">
                      <FaUserAlt size="50" />
                      <h3>All Users</h3>
                      <p>Get all user details which is in our system.</p>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            {(localStorage.getItem("h-kck-auth-role") === "ADMIN" ||
              localStorage.getItem("h-kck-auth-role") === "EDITOR") && (
              <>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-4">
                    <Link to="/tournament">
                      <div className="card-h-div p-4 mb-4">
                        <FaListAlt size="50" />
                        <h3>All Tournaments</h3>
                        <p>
                          Get all tournament details which is in our system.
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4">
                    <Link to="/match">
                      <div className="card-h-div p-4 mb-4">
                        <FaListAlt size="50" />
                        <h3>All Matches</h3>
                        <p>Get all match details which is in our system.</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-4">
                    <Link to="/team">
                      <div className="card-h-div p-4 mb-4">
                        <FaListAlt size="50" />
                        <h3>All Teams</h3>
                        <p>Get all Team details which is in our system.</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
