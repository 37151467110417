import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { MdViewHeadline } from "react-icons/md";

import { readMatchesByStatus } from "../../../../api/matchAPI";
import renderMatchType, {
  renderMatchStatus,
} from "../../../../meta/renderMatchBlocks";

import "./AllNotStartedMatches.css";

const AllNotStartedMatches = () => {
  const [matchItemsItems, setMatchItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readMatchesByStatus({ status: "NOT YET" }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setMatchItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [matchItemsItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => renderMatchStatus(row.status),
        grow: 2,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        grow: 3,
      },
      {
        name: "Location",
        selector: (row) => row.location,
        sortable: true,
      },
      {
        name: "Responsible",
        selector: (row) => row.responsible,
        sortable: true,
        grow: 2,
      },
      {
        name: "Type",
        selector: (row) => renderMatchType(row.type),
      },
      {
        name: "Start Time",
        selector: (row) => row.startTime,
        sortable: true,
        grow: 2,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <Link to={`match/${row.matchId}`} className="">
              <MdViewHeadline size={20} />
            </Link>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 mb-5">
          <div className="table_outer_div">
            <DataTable
              title="Upcoming Matches"
              data={matchItemsItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNotStartedMatches;
