import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { createTournament } from "../../../../api/tournamentAPI";

import "./CreateTournamentForm.css";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      minDate={new Date()}
      placeholderText="Tournament Start Date"
      showTimeSelect
      dateFormat="yyyy-MM-dd h:mm aa"
      withPortal
      showYearDropdown
      scrollableYearDropdown
      timeIntervals={15}
    />
  );
};

const CreateTournamentForm = ({ setOpenCreateTournamentModal }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenCreateTournamentModal(false);
    window.location.href = "/tournament";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                name: "",
                type: "LEAGUE",
                startDate: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(5, "Name cannot smaller than 5 characters")
                  .max(100, "Name cannot exceed 100 characters")
                  .required("Required"),
                type: Yup.string().required("Required"),
                startDate: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                createTournament({
                  name: values.name,
                  type: values.type,
                  startDate: moment(values.startDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Tournament Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="name">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="type"
                            as="select"
                            className="form-control"
                            placeholder="Select Tournament Type"
                            disabled={isLoading}
                          >
                            <option value="KNOCKOUT">KNOCKOUT</option>
                            <option value="LEAGUE">LEAGUE</option>
                          </Field>
                          <ErrorMessage name="type">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <DatePickerField
                            className="form-control"
                            name="startDate"
                          />
                          <ErrorMessage name="startDate">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Create New Tournament
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTournamentForm;
