import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { teamReadByPath } from "../../../api/teamAPI";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import AddPlayerToTeamForm from "../forms/addPlayerToTeamForm/AddPlayerToTeamForm";
import CurrentPlayerByTeam from "../currentPlayerByTeam/CurrentPlayerByTeam";
import PastPlayerByTeam from "../pastPlayerByTeam/PastPlayerByTeam";

function ViewTeam() {
  let { teamPath } = useParams();
  const [team, setTeam] = useState(null);
  const [pending, setPending] = useState(true);
  const [openAddPlayerTeamModal, setOpenAddPlayerTeamModal] = useState(false);

  useEffect(() => {
    teamReadByPath({ teamPath }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeam(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [team?.userId, teamPath]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <button
                type="button"
                className="button-add-style"
                onClick={() => {
                  setOpenAddPlayerTeamModal(true);
                }}
              >
                <AiOutlinePlus /> Add Player to Team
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <div className="table_outer_div">
                <div className="col-lg-12 header-txt">
                  <h5>Team Details</h5>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{team?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Type</b>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>{team?.responsible}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Last Modified</b>
                      </td>
                      <td>{team?.created}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Created</b>
                      </td>
                      <td>{team?.modified}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-sm-7">
              <CurrentPlayerByTeam teamId={team?.teamId} />
              <PastPlayerByTeam teamId={team?.teamId} />
            </div>
          </div>
          <Modal
            isOpen={openAddPlayerTeamModal}
            toggle={() => setOpenAddPlayerTeamModal(!openAddPlayerTeamModal)}
          >
            <ModalHeader
              className="modal-head"
              toggle={() => setOpenAddPlayerTeamModal(!openAddPlayerTeamModal)}
            >
              Add new Player to Team
            </ModalHeader>
            <ModalBody>
              <AddPlayerToTeamForm
                setOpenAddPlayerTeamModal={setOpenAddPlayerTeamModal}
                teamId={team?.teamId}
              />
            </ModalBody>
          </Modal>
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default ViewTeam;
