export default function requiredPlayerNumber(type) {
  switch (type) {
    case "6S":
      return [6, 10];
    case "7S":
      return [7, 12];
    case "11S":
      return [11, 18];
    default:
      return [11, 18];
  }
}
