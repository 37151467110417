import React from "react";

function Page404() {
	return (
		<div>
			<h1>Page not found</h1>
		</div>
	);
}

export default Page404;
