import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import AddGoalForm from "../../forms/addGoalForm/AddGoalForm";

import "./AddGoal.css";

function AddGoal({
  matchId,
  teams,
  quarter,
  quarterTime,
  goalAddSuccess,
  scoreCardId,
  addGoalClick,
  time,
}) {
  const [openAddGoalModal, setOpenAddGoalModal] = useState(false);

  const addGoalBtnClick = () => {
    addGoalClick();
    setOpenAddGoalModal(true);
  };

  return (
    <>
      <div
        className={time === 0 ? "add-goal-div-disable" : "add-goal-div"}
        onClick={() => {
          addGoalBtnClick();
        }}
      >
        <div className="container pt-5 pb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-6 score-left-div">
              <h1 className="team-head">Add a Goal</h1>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openAddGoalModal}
        toggle={() => setOpenAddGoalModal(!openAddGoalModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenAddGoalModal(!openAddGoalModal)}
        >
          Add a Goal
        </ModalHeader>
        <ModalBody>
          <AddGoalForm
            matchId={matchId}
            scoreCardId={scoreCardId}
            teams={teams}
            quarter={quarter}
            quarterTime={quarterTime}
            setOpenAddGoalModal={setOpenAddGoalModal}
            goalAddSuccess={goalAddSuccess}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddGoal;
