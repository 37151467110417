import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { FaEdit } from "react-icons/fa";
import { AiOutlineUserAdd } from "react-icons/ai";

import { readAllUsers } from "../../../api/authAPI";
import renderUserLevel from "../../../meta/renderUserLevel";
import EditRoleState from "../forms/editRoleState/EditRoleState";
import SignIn from "../forms/signIn/SignIn";
import renderPlayingState from "../../../meta/renderPlayingState";

import "./AllUsers.css";

const AllUsers = () => {
  const [userItems, setUserItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [clickedUser, setClickedUser] = useState();

  const userEditClick = (value) => {
    setOpenEditModal(true);
    setClickedUser(value);
  };

  useEffect(() => {
    readAllUsers().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setUserItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [userItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => renderPlayingState(row.playingState),
      },
      {
        name: "First Name",
        selector: (row) => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: (row) => row.lastName,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        grow: 3,
      },
      {
        name: "Phone Number",
        selector: (row) => row.phoneNumber,
        sortable: true,
        grow: 2,
      },
      {
        name: "Birth Date",
        selector: (row) => row.birthDate,
        sortable: true,
        grow: 2,
      },
      {
        name: "Role",
        selector: (row) => renderUserLevel(row.role),
      },
      {
        name: "Modified",
        selector: (row) => row.modified,
        sortable: true,
        grow: 2,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <button
              className="btn-link-style"
              onClick={() => userEditClick(row)}
            >
              <FaEdit />
            </button>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenCreateUserModal(true);
            }}
          >
            <AiOutlineUserAdd /> Add User
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Users"
              data={userItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openEditModal}
        toggle={() => setOpenEditModal(!openEditModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenEditModal(!openEditModal)}
        >
          Edit User |{" "}
          {clickedUser && `${clickedUser.firstName} ${clickedUser.lastName}`}
        </ModalHeader>
        <ModalBody>
          <EditRoleState
            userObject={clickedUser}
            setOpenEditModal={setOpenEditModal}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openCreateUserModal}
        toggle={() => setOpenCreateUserModal(!openCreateUserModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenCreateUserModal(!openCreateUserModal)}
        >
          Add New User
        </ModalHeader>
        <ModalBody>
          <SignIn setOpenCreateUserModal={setOpenCreateUserModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllUsers;
