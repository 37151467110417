import { useEffect, useState } from "react";
import { Label, Message } from "semantic-ui-react";
import { Modal, ModalHeader, ModalBody, FormGroup, Alert } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { addPlayerListToMatch } from "../../../../api/matchPlayerAPI";
import requiredPlayerNumber from "../../../../meta/playerListConditionCheck";
import { getPlayerListToMatchByTeam } from "../../../../api/teamUserAPI";

import "./AddPlayersToTeamModal.css";

function AddPlayersToTeamModal({
  matchId,
  matchType,
  selectedPlayersInChangingTeam,
  selectedPlayersInOtherTeam,
  clickedPlayerIndex,
  clickedTeamId,
}) {
  const reqPlayerNum = requiredPlayerNumber(matchType);
  const [leftPlayerList, setLeftPlayerList] = useState([]);

  const [rightPlayerList, setRightPlayerList] = useState(
    selectedPlayersInChangingTeam
  );
  const [tShirtNumberRoleModal, setTShirtNumberRoleModal] = useState(false);
  const [currentClickedPlayer, setCurrentClickedPlayer] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");

  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [errorMsgApiCall, setErrorMsgApiCall] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [submitBtnEnable, setSubmitBtnEnable] = useState(false);
  const [isFailSubmissionApiCall, setIsFailSubmissionApiCall] = useState(false);

  useEffect(() => {
    getPlayerListToMatchByTeam({ teamId: clickedTeamId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            let newArr = data["data"];

            rightPlayerList.map((iPlayer, i) => {
              data["data"].map((jPlayer, j) => {
                if (iPlayer.userId === jPlayer.userId) {
                  newArr.splice(
                    newArr.findIndex(function (obj) {
                      return obj.userId === iPlayer.userId;
                    }),
                    1
                  );
                }
              });
            });
            setLeftPlayerList(newArr);

            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, []);

  const leftUserClicked = (value, index) => {
    if (reqPlayerNum[1] <= rightPlayerList.length) {
      setIsFailSubmission(true);
      setErrorMsg({
        head: "",
        sub: "You have added the required number of players! Please submit the Team!",
      });
    } else {
      setCurrentClickedPlayer(value);
      setCurrentIndex(index);
      setTShirtNumberRoleModal(true);
    }
  };

  const rightUserClicked = (value, index) => {
    setIsFailSubmission(false);
    if (rightPlayerList.length - 1 < reqPlayerNum[0]) {
      setIsFailSubmission(true);
      setErrorMsg({
        head: "",
        sub: `Required minimum number of players are ${reqPlayerNum[0]}!`,
      });
    } else {
      setLeftPlayerList([
        ...leftPlayerList,
        {
          userId: value.userId,
          userName: value.userName,
        },
      ]);

      rightPlayerList.splice(index, 1);
      setSubmitBtnEnable(true);
    }
  };

  function successSubmission() {
    window.location.href = `/match/${matchId}`;
  }

  function failSubmission(head, sub) {
    setIsFailSubmissionApiCall(true);
    setPending(false);
    setErrorMsgApiCall({
      head: head,
      sub: sub,
    });
  }

  const addPlayerListClick = () => {
    addPlayerListToMatch({
      matchId: matchId,
      teamOnePlayers:
        clickedPlayerIndex === "ONE"
          ? JSON.stringify(rightPlayerList)
          : selectedPlayersInOtherTeam
          ? JSON.stringify(selectedPlayersInOtherTeam)
          : null,
      teamTwoPlayers:
        clickedPlayerIndex === "TWO"
          ? JSON.stringify(rightPlayerList)
          : selectedPlayersInOtherTeam
          ? JSON.stringify(selectedPlayersInOtherTeam)
          : null,
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            successSubmission(data["data"]);
          } else if (data["result"] === "FAILED") {
            failSubmission("Error!", data["data"]["errorMessage"]);
          } else {
            failSubmission("Error!", "Please try again later!");
          }
        } else {
          failSubmission("Error!", "Please try again later!");
        }
      } catch (error) {
        failSubmission("Error!", "Please try again later!");
      }
    });
  };

  return (
    <div className="container add-player-modal">
      <div className="row">
        <div className="col-sm-6 mt-3 left-div">
          <Message color="green">
            <Message.Header>Available player List</Message.Header>
          </Message>
          {leftPlayerList &&
            leftPlayerList.map(function (player, index) {
              return (
                <Label
                  key={index}
                  as="a"
                  onClick={() => leftUserClicked(player, index)}
                >
                  <h4>{player.userName}</h4>
                </Label>
              );
            })}
        </div>
        <div className="col-sm-6 mt-3 right-div">
          <Message color="purple">
            <Message.Header>Players play in the Match</Message.Header>
          </Message>
          {rightPlayerList &&
            rightPlayerList.map(function (player, index) {
              return (
                <Label
                  key={index}
                  as="a"
                  onClick={() => rightUserClicked(player, index)}
                >
                  <h4>{`${player.tNumber} | ${player.userName} | ${player.role}`}</h4>
                </Label>
              );
            })}
        </div>
      </div>
      <Modal
        isOpen={tShirtNumberRoleModal}
        toggle={() => setTShirtNumberRoleModal(!tShirtNumberRoleModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setTShirtNumberRoleModal(!tShirtNumberRoleModal)}
        >
          Add T-Shirt Number
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12">
                <div className="invoice-form-div">
                  <Formik
                    initialValues={{
                      tShirtNumber: "",
                      role: "LF",
                    }}
                    validationSchema={Yup.object({
                      tShirtNumber: Yup.number()
                        .typeError("You must specify a number")
                        .min(0, "Min value 0")
                        .max(999, "Max value 999")
                        .required("Required"),
                      role: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setIsLoading(true);
                      setIsFailSubmission(false);

                      setRightPlayerList([
                        ...rightPlayerList,
                        {
                          userId: currentClickedPlayer.userId,
                          userName: currentClickedPlayer.userName,
                          role: values.role,
                          tNumber: values.tShirtNumber,
                        },
                      ]);

                      leftPlayerList.splice(currentIndex, 1);

                      setTShirtNumberRoleModal(false);
                      setIsLoading(false);

                      if (rightPlayerList.length + 1 >= reqPlayerNum[0]) {
                        setSubmitBtnEnable(true);
                      }
                    }}
                  >
                    <Form>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="tShirtNumber"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter T-Shirt Number"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="tShirtNumber">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="role"
                                  as="select"
                                  className="form-control"
                                  placeholder="Select Playing Role"
                                  disabled={isLoading}
                                >
                                  <option value="LF">LEFT FULLBACK</option>
                                  <option value="LH">LEFT HALF</option>
                                  <option value="LO">LEFT OUT</option>
                                  <option value="LI">LEFT IN</option>
                                  <option value="CH">CENTRE HALF</option>
                                  <option value="CF">CENTRE FORWARD</option>
                                  <option value="RI">RIGHT IN</option>
                                  <option value="RH">RIGHT HALF</option>
                                  <option value="RO">RIGHT OUT</option>
                                  <option value="RF">RIGHT FULLBACK</option>
                                  <option value="GK">GOALKEEPER</option>
                                </Field>
                                <ErrorMessage name="role">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="button-submit-style"
                                  disabled={isLoading}
                                >
                                  Add Player to Match
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {isFailSubmissionApiCall && (
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <>
                <Alert color="danger">
                  <p className="mb-0">{errorMsgApiCall.sub}</p>
                </Alert>
              </>
            </div>
          </div>
        </div>
      )}
      {isFailSubmission && (
        <div className="container">
          <div className="row">
            <div className="col text-center mt-3">
              <Alert color="danger" onClose={() => setIsFailSubmission(false)}>
                <p className="mb-0">{errorMsg.sub}</p>
              </Alert>
            </div>
          </div>
        </div>
      )}
      {submitBtnEnable && (
        <div className="row">
          <div className="col-sm-12 text-center mt-1">
            <button
              type="submit"
              className="button-submit-style"
              onClick={() => {
                addPlayerListClick();
              }}
              disabled={pending}
            >
              Submit Player List
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddPlayersToTeamModal;
