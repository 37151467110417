import axios from "axios";

export const getPlayerListInMatch = async ({ matchId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/matchPlayer/read_players_by_match.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addPlayerListToMatch = async ({
  matchId,
  teamOnePlayers,
  teamTwoPlayers,
}) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("teamOnePlayers", teamOnePlayers);
    formData.append("teamTwoPlayers", teamTwoPlayers);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/matchPlayer/add.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
