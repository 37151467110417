import axios from "axios";

export const createMatch = async ({
  name,
  type,
  teamOneId,
  teamTwoId,
  quarterTime,
  numQuarter,
  numPenalty,
  location,
  tournamentId,
  scorerId,
  startTime,
}) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("name", name);
    formData.append("type", type);
    formData.append("teamOneId", teamOneId);
    formData.append("teamTwoId", teamTwoId);
    formData.append("quarterTime", quarterTime);
    formData.append("numQuarter", numQuarter);
    formData.append("numPenalty", numPenalty);
    formData.append("location", location);
    formData.append("tournamentId", tournamentId);
    formData.append("scorerId", scorerId);
    formData.append("startTime", startTime);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readMatchesByStatus = async ({ status }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("status", status);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_by_status.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readMatchesById = async ({ matchId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateMatchStatus = async ({ matchId, status, scoreCardId }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("scoreCardId", scoreCardId);
    formData.append("status", status);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/update_match_status.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateMatchWinner = async ({ matchId, winner }) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("winner", winner);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/update_winner.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readMatchesByTournamentIdAndStatus = async ({
  tournamentId,
  status,
}) => {
  try {
    const token = localStorage.getItem("h-kck-auth-token");
    let formData = new FormData();

    formData.append("tournamentId", tournamentId);
    formData.append("status", status);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_by_tournament_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
