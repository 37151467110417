import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { updateCurrentPlayerState } from "../../../../api/teamUserAPI";

import "./ChangePlayerTeamStateForm.css";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      placeholderText="Contract end Date"
      dateFormat="yyyy-MM-dd"
      withPortal
      showYearDropdown
      scrollableYearDropdown
    />
  );
};

const ChangePlayerTeamStateForm = ({
  userId,
  teamId,
  state,
  setOpenEditModal,
}) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  console.log(userId, teamId, state);
  function successSubmission() {
    setOpenEditModal(false);
    window.location.reload();
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                state: state,
                leaveDate: "",
              }}
              validationSchema={Yup.object({
                state: Yup.string().required("Required"),
                leaveDate: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);
                updateCurrentPlayerState({
                  userId: userId,
                  teamId: teamId,
                  state: values.state,
                  leaveDate: moment(values.leaveDate).format("YYYY-MM-DD"),
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="state"
                            as="select"
                            className="form-control"
                            placeholder="Select user State"
                            disabled={isLoading}
                          >
                            <option value="CURRENT">CURRENT</option>
                            <option value="PAST">PAST</option>
                          </Field>
                          <ErrorMessage name="state">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <DatePickerField
                            className="form-control"
                            name="leaveDate"
                          />
                          <ErrorMessage name="leaveDate">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Update User State
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlayerTeamStateForm;
